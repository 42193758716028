import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import clientService from './clientService';

const initialState = {
  client: null,
  damages: null,
  damage: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: ''
};

// Add Client
export const addClientSetup = createAsyncThunk('client_setup/add_setup', async (clientSetupData, thunkAPI) => {
  try {
    return await clientService.addClientSetup(clientSetupData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Client Detail
export const fetchClientDetail = createAsyncThunk('client_setup/setup_detail', async (data, thunkAPI) => {
  try {
    return await clientService.fetchClientDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add Damage Setup
export const addDamageSetup = createAsyncThunk('common_setup/add_damage_setup', async (damageSetupData, thunkAPI) => {
  try {
    return await clientService.addDamageSetup(damageSetupData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Damage Setup List
export const fetchDamageSetupList = createAsyncThunk('common_setup/list_damage_setup', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await clientService.fetchDamageSetupList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Damage Setup Detail
export const fetchDamageSetupDetail = createAsyncThunk('common_setup/damage_setup_detail', async (data, thunkAPI) => {
  try {
    return await clientService.fetchDamageSetupDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Damage Setup Detail
export const updateDamageSetup = createAsyncThunk('common_setup/edit_damage_setup', async (data, thunkAPI) => {
  try {
    return await clientService.updateDamageSetup(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Damage Setup Status
export const damageSetupStatus = createAsyncThunk('common_setup/damage_status_update', async (data, thunkAPI) => {
  try {
    return await clientService.damageSetupStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const clientSlice = createSlice({
  name: 'clientSetup',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addClientSetup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addClientSetup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.client = action.payload;
      })
      .addCase(addClientSetup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchClientDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClientDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.client = action.payload;
      })
      .addCase(fetchClientDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addDamageSetup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addDamageSetup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.damage = action.payload;
      })
      .addCase(addDamageSetup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchDamageSetupList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDamageSetupList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.damages = action.payload;
      })
      .addCase(fetchDamageSetupList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchDamageSetupDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDamageSetupDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.damages = action.payload;
      })
      .addCase(fetchDamageSetupDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateDamageSetup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDamageSetup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.damage = action.payload;
      })
      .addCase(updateDamageSetup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(damageSetupStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(damageSetupStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.damage = action.payload;
      })
      .addCase(damageSetupStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = clientSlice.actions;
export default clientSlice.reducer;
