import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

const addClientSetup = async (clientSetupData) => {
  const config = {
    method: 'post',
    url: API_URL + 'client_setup/add_setup',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: clientSetupData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchClientDetail = async (organizationId) => {
  const config = {
    method: 'post',
    url: API_URL + `client_setup/setup_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Damage Setup
const addDamageSetup = async (damageSetupData) => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/add_damage_setup',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: damageSetupData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchDamageSetupList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `common_setup/list_damage_setup?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchDamageSetupDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `common_setup/damage_setup_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateDamageSetup = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/edit_damage_setup',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const damageSetupStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `common_setup/damage_status_update`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const clientService = {
  addClientSetup,
  fetchClientDetail,
  addDamageSetup,
  fetchDamageSetupList,
  fetchDamageSetupDetail,
  updateDamageSetup,
  damageSetupStatus
};

export default clientService;
