import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
// const API_URL = process.env.REACT_APP_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

const postData = async (organizationData) => {
  const config = {
    method: 'post',
    url: API_URL + 'organization/add',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: organizationData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateOrganization = async (newOrganizationData) => {
  const config = {
    method: 'post',
    url: API_URL + 'organization/updatebasic',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: newOrganizationData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchOrganizationDetail = async (_id) => {
  const data = {
    _id: _id
  };
  const config = {
    method: 'post',
    url: API_URL + `organization/getsingleorganization`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const organizationAddAddress = async (addressData) => {
  const config = {
    method: 'post',
    url: API_URL + 'organization/addressupdate',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: addressData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const organizationAuthorizationUpdate = async (authorizationData) => {
  const config = {
    method: 'post',
    url: API_URL + 'organization/authorizationupdate',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: authorizationData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const organizationDocumentsUpdate = async (documentsData) => {
  const config = {
    method: 'post',
    url: API_URL + 'organization/updatedocument',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: documentsData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const organizationBankDetailsUpdate = async (bankDetailsData) => {
  const config = {
    method: 'post',
    url: API_URL + 'organization/bankdetailupdate',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: bankDetailsData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchOrganizationProducts = async () => {
  const config = {
    method: 'post',
    url: API_URL + 'organization/getallproducts',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchCustomerProducts = async () => {
  const config = {
    method: 'post',
    url: API_URL + 'organization/get_organization_product',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchOrganizations = async (page, orgType, organizationSubType) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `organization/getall?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      orgType: orgType,
      organizationSubType: organizationSubType
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const organizationStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `organization/status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchClientList = async () => {
  const config = {
    method: 'post',
    url: API_URL + `organization/client_list`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const organizationService = {
  postData,
  updateOrganization,
  fetchOrganizationDetail,
  organizationAddAddress,
  organizationAuthorizationUpdate,
  organizationDocumentsUpdate,
  organizationBankDetailsUpdate,
  fetchOrganizationProducts,
  fetchCustomerProducts,
  fetchOrganizations,
  organizationStatus,
  fetchClientList
};

export default organizationService;
