// React Import
import { useMemo } from 'react';
import { useEffect, useState } from 'react';

// material-ui
import { Box, Chip, useMediaQuery } from '@mui/material';

// project import
// import useConfig from 'hooks/useConfig';
import Search from './Search';
import Message from './Message';
import Profile from './Profile';
// import Localization from './Localization';
import WarehouseCustomerFilter from './WarehouseCustomerFilter';
import Notification from './Notification';
import MobileSection from './MobileSection';
import { useLocation, useNavigate } from 'react-router';
import MegaMenuSection from '../../../CommonLayout/HeaderContent/MegaMenuSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  // const { i18n } = useConfig();
  const location = useLocation();
  const navigate = useNavigate();
  const pathArray = location.pathname.split('/');
  const selectedProduct = pathArray[1];
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [filteredData, setFilteredData] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const localization = useMemo(() => <Localization />, [i18n]);
  const megaMenu = useMemo(() => <MegaMenuSection />, []);

  const filterValue = (data) => {
    setFilteredData(data);
  };

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user === null) {
      navigate('/login');
    } else if (filteredData === null) {
      let data;
      if (selectedProduct === 'oms' && user.organizationType === 2) {
        data = JSON.parse(localStorage.getItem('omsFilteredData'));
      } else if ((selectedProduct === 'wms' || selectedProduct === 'tms') && user.organizationType === 2) {
        data = JSON.parse(localStorage.getItem('wmsFilteredData'));
      } else if (user.organizationType === 3 && user.organizationSubType === 3) {
        data = JSON.parse(localStorage.getItem('clientFilteredData'));
      } else if (user.organizationType === 3 && user.organizationSubType === 4) {
        data = JSON.parse(localStorage.getItem('whPartnerFilteredData'));
      }
      setFilteredData(data);
    }
  }, [filteredData, navigate, selectedProduct]);

  return (
    <>
      {!matchesXs && <Search />}
      {/* {!matchesXs && localization} */}
      {selectedProduct === 'oms' && !matchesXs && filteredData && user.organizationType === 2 && filteredData.client && (
        <Chip size="small" color="primary" label={`Customer: ${filteredData.client.organizationName}`} sx={{ marginRight: 1 }} />
      )}
      {selectedProduct === 'oms' && !matchesXs && filteredData && user.organizationType === 2 && filteredData.warehouse.length > 0 && (
        <Chip
          size="small"
          color="primary"
          label={`Warehouse: ${
            filteredData.warehouse.length > 1
              ? `Multiple (${filteredData.warehouse.length})`
              : filteredData.warehouse[0].warehouseDisplayName
          }`}
        />
      )}
      {selectedProduct === 'wms' && !matchesXs && filteredData && user.organizationType === 2 && filteredData.client && (
        <Chip size="small" color="primary" label={`Customer: ${filteredData.client.clientName}`} sx={{ marginRight: 1 }} />
      )}
      {selectedProduct === 'wms' && !matchesXs && filteredData && user.organizationType === 2 && filteredData.warehouse && (
        <Chip size="small" color="primary" label={`Warehouse: ${filteredData.warehouse.warehouseDisplayName}`} sx={{ marginRight: 1 }} />
      )}
      {!matchesXs && filteredData && user.organizationType === 3 && user.organizationSubType === 3 && filteredData.warehouse.length > 0 && (
        <Chip
          size="small"
          color="primary"
          label={`Warehouse: ${
            filteredData.warehouse.length > 1
              ? `Multiple (${filteredData.warehouse.length})`
              : filteredData.warehouse[0].warehouseDisplayName
          }`}
        />
      )}
      {!matchesXs && filteredData && user.organizationType === 3 && user.organizationSubType === 4 && filteredData.client && (
        <Chip size="small" color="primary" label={`Customer: ${filteredData.client.clientName}`} sx={{ marginRight: 1 }} />
      )}
      {!matchesXs && filteredData && user.organizationType === 3 && user.organizationSubType === 4 && filteredData.warehouse && (
        <Chip size="small" color="primary" label={`Warehouse: ${filteredData.warehouse.warehouseDisplayName}`} sx={{ marginRight: 1 }} />
      )}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {!matchesXs && megaMenu}
      <Notification />
      <Message />
      {user && (user.organizationType === 2 || user.organizationType === 3) && <WarehouseCustomerFilter filterValue={filterValue} />}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
