import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filterValue, setFilterValue] = useState(null);

  return <FilterContext.Provider value={{ filterValue, setFilterValue }}>{children}</FilterContext.Provider>;
};
FilterProvider.propTypes = {
  children: PropTypes.node
};
