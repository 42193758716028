// React Import
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// material-ui
import { Grid, Stack, InputLabel, Button, Typography, Autocomplete, TextField, CircularProgress, LinearProgress } from '@mui/material';

// project import
import { Formik } from 'formik';
import * as Yup from 'yup';
import AnimateButton from 'components/@extended/AnimateButton';
import { fetchFilteredClient, fetchFilteredWarehouse, reset } from 'store/reducers/features/authentication/authenticationSlice';

// ==============================|| Warehouse & Customer Filter ||============================== //

const OmsFilter = (props) => {
  const { onCancel, onFilterChange } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const { clients, isError, message } = useSelector((state) => state.authentication);
  const userType = JSON.parse(localStorage.getItem('user'))?.userType;

  useEffect(() => {
    if (isError) {
      console.log(message);
    }
    dispatch(fetchFilteredClient(userType));
    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch, userType]);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  //Fetch Warehouse
  function fetchWarehouse(value) {
    setLoading(true);
    const data = {
      clientId: value,
      userType: userType
    };
    dispatch(fetchFilteredWarehouse(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          setWarehouseList(res.data);
          localStorage.setItem('warehouseList', JSON.stringify(res.data));
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log('Error fetching permissions:', e);
        setLoading(false);
      });
  }

  const omsFilteredData = JSON.parse(localStorage.getItem('omsFilteredData'));
  const warehouses = JSON.parse(localStorage.getItem('warehouseList'));

  return (
    <>
      <Formik
        initialValues={{
          client: omsFilteredData ? omsFilteredData.client : null,
          warehouse: omsFilteredData ? omsFilteredData.warehouse : []
        }}
        validationSchema={Yup.object().shape({
          client: Yup.object().nullable(),
          warehouse: Yup.object().nullable()
        })}
        onSubmit={async (values) => {
          values.client === null && localStorage.removeItem('warehouseList');
          localStorage.setItem('omsFilteredData', JSON.stringify(values));
          isMounted && onCancel(), onFilterChange();
        }}
      >
        {({ setFieldValue, handleBlur, handleChange, handleSubmit, isSubmitting, values, resetForm }) => (
          <form noValidate autoComplete="off" onSubmit={handleSubmit} onReset={resetForm}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h5" component="div">
                    Warehouse & Customer Filter
                  </Typography>
                </Stack>
                <hr></hr>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={1}>
                  <InputLabel id="client">Client</InputLabel>
                  <Autocomplete
                    id="client"
                    size="small"
                    name="client"
                    fullWidth
                    options={clients}
                    autoHighlight
                    value={values.client}
                    getOptionLabel={(option) => option.organizationName}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    onChange={(e, data) => {
                      if (data) {
                        setFieldValue('warehouse', []);
                        fetchWarehouse(data._id);
                      } else {
                        setFieldValue('warehouse', []);
                      }
                      setFieldValue('client', data);
                    }}
                    renderInput={(params) => <TextField name="client" {...params} placeholder="Select Client" />}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        p: 1
                      },
                      '& .MuiAutocomplete-tag': {
                        bgcolor: 'primary.lighter',
                        border: '1px solid',
                        borderColor: 'primary.light',
                        '& .MuiSvgIcon-root': {
                          color: 'primary.main',
                          '&:hover': {
                            color: 'primary.dark'
                          }
                        }
                      }
                    }}
                  />
                </Stack>
              </Grid>
              {!loading ? (
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel id="warehouse">Warehouse</InputLabel>
                    <Autocomplete
                      multiple
                      size="small"
                      id="warehouse"
                      options={warehouses ? warehouses : warehouseList}
                      getOptionLabel={(option) => option.warehouseDisplayName}
                      value={values.warehouse}
                      name="warehouse"
                      onChange={(e, data) => {
                        handleChange;
                        setFieldValue('warehouse', data);
                      }}
                      onBlur={handleBlur}
                      disabled={values.client === null ? true : false}
                      isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
                      renderInput={(params) => <TextField {...params} placeholder="Select Warehouse" />}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          p: 1
                        },
                        '& .MuiAutocomplete-tag': {
                          bgcolor: 'primary.lighter',
                          border: '1px solid',
                          borderColor: 'primary.light',
                          '& .MuiSvgIcon-root': {
                            color: 'primary.main',
                            '&:hover': {
                              color: 'primary.dark'
                            }
                          }
                        }
                      }}
                    />
                  </Stack>
                </Grid>
              ) : (
                <>
                  <LinearProgress />
                </>
              )}
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end">
                  <AnimateButton>
                    <Button
                      size="small"
                      sx={{ my: 2 }}
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {isSubmitting && <CircularProgress size={15} thickness={4} />}
                      Search
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default OmsFilter;
OmsFilter.propTypes = {
  onCancel: PropTypes.func,
  onFilterChange: PropTypes.func
};
