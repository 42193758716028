import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import vendorService from './vendorService';

const initialState = {
  vendor: null,
  vendors: null,
  item: null,
  catalogue: null,
  catalogues: null,
  vendorAddress: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: ''
};

// Add Vendor
export const addVendor = createAsyncThunk('vendor/add', async (vendorData, thunkAPI) => {
  try {
    return await vendorService.addVendor(vendorData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Vendor List
export const fetchVendorList = createAsyncThunk('vendor/list', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await vendorService.fetchVendorList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Vendor Status Toggle
export const vendorStatus = createAsyncThunk('vendor/status', async (data, thunkAPI) => {
  try {
    return await vendorService.vendorStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Single Vendor Detail
export const fetchVendorDetail = createAsyncThunk('vendor/get_single', async (data, thunkAPI) => {
  try {
    return await vendorService.fetchVendorDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Vendor Updation
export const updateVendor = createAsyncThunk('vendor/edit', async (data, thunkAPI) => {
  try {
    return await vendorService.updateVendor(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch ALL Item List
export const fetchAllItemsList = createAsyncThunk('item/all_item_list', async (data, thunkAPI) => {
  try {
    return await vendorService.fetchAllItemsList(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add Catalogue
export const addCatalogue = createAsyncThunk('vendor/add_catalogue', async (catalogueData, thunkAPI) => {
  try {
    return await vendorService.addCatalogue(catalogueData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// fetch Catalogue
export const fetchVendorCatalogue = createAsyncThunk('vendor/catalogue', async ({ pageNum, limitNum, orgId, vendorId }, thunkAPI) => {
  try {
    return await vendorService.fetchVendorCatalogue(pageNum, limitNum, orgId, vendorId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// fetch Catalogue status
export const catalogueStatus = createAsyncThunk('vendor/catalogue_status', async (data, thunkAPI) => {
  try {
    return await vendorService.catalogueStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add Vendor Address
export const addVendorAddress = createAsyncThunk('vendor/add_address', async (vendorAddressData, thunkAPI) => {
  try {
    return await vendorService.addVendorAddress(vendorAddressData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Vendor Address List
export const fetchVendorAddressList = createAsyncThunk('vendor/list_address', async ({ pageNum, vendorId }, thunkAPI) => {
  try {
    return await vendorService.fetchVendorAddressList(pageNum, vendorId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Vendor Address Detail
export const fetchVendorAddressDetail = createAsyncThunk('vendor/address_detail', async (data, thunkAPI) => {
  try {
    return await vendorService.fetchVendorAddressDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Vendor Address Updation
export const updateVendorAddress = createAsyncThunk('vendor/update_address', async (data, thunkAPI) => {
  try {
    return await vendorService.updateVendorAddress(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addVendor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendor = action.payload;
      })
      .addCase(addVendor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchVendorList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVendorList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendors = action.payload;
      })
      .addCase(fetchVendorList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(vendorStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(vendorStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendor = action.payload;
      })
      .addCase(vendorStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchVendorDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVendorDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendor = action.payload;
      })
      .addCase(fetchVendorDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVendor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendor = action.payload;
      })
      .addCase(updateVendor.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchAllItemsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllItemsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.item = action.payload;
      })
      .addCase(fetchAllItemsList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addCatalogue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCatalogue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.catalogue = action.payload;
      })
      .addCase(addCatalogue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchVendorCatalogue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVendorCatalogue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.catalogues = action.payload;
      })
      .addCase(fetchVendorCatalogue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(catalogueStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(catalogueStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.catalogue = action.payload;
      })
      .addCase(catalogueStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addVendorAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addVendorAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendorAddress = action.payload;
      })
      .addCase(addVendorAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchVendorAddressList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVendorAddressList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendorAddress = action.payload;
      })
      .addCase(fetchVendorAddressList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchVendorAddressDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVendorAddressDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendorAddress = action.payload;
      })
      .addCase(fetchVendorAddressDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateVendorAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVendorAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.vendorAddress = action.payload;
      })
      .addCase(updateVendorAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = vendorSlice.actions;
export default vendorSlice.reducer;
