import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
// const API_URL = process.env.REACT_APP_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

// Add Permissions
const addPermissions = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/add_permission',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Fetch Permissions
const fetchPermissions = async (page) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `common_setup/fetch_permissions?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Fetch Permissions Products
const fetchPermissionProducts = async () => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/fetch_permission_products',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Fetch Role Permissions
const fetchRolePermission = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/fetch_organization_permission',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Add Role Permissions
const addRolePermission = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/add_role_permissions',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Edit Role Permissions
const editRolePermission = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/edit_role_permissions',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Role List for table
const rolesList = async (page) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `common_setup/list_roles?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Single Role Permissions
const singleRolePermissions = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/list_role_permissions',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Add Employee
const addEmployee = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/add_employee',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// List Employee
const listEmployee = async (page) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `common_setup/list_employee?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// List Warehouse Assign
const listWarehouseAssign = async () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const data = {
    organizationType: user.organizationType
  };
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/list_all',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Fetch Warehouse Customers
const fetchWarehouseCustomers = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/all_booking_space',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Fetch Employee Detail
const fetchEmployeeDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/employee_details',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Employee Status
const employeeStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `common_setup/update_status`,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

//Employee Update
const updateEmployee = async (newEmployeeData) => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/edit_employee',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: newEmployeeData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

//Fetch Side Menu
const fetchSideMenu = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'common_setup/fetch_side_menu',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const permissionService = {
  addPermissions,
  fetchPermissions,
  fetchPermissionProducts,
  fetchRolePermission,
  addRolePermission,
  editRolePermission,
  rolesList,
  singleRolePermissions,
  addEmployee,
  listEmployee,
  listWarehouseAssign,
  fetchWarehouseCustomers,
  fetchEmployeeDetail,
  employeeStatus,
  updateEmployee,
  fetchSideMenu
};

export default permissionService;
