import { lazy } from 'react';

// project import

import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteAccessCheck from 'utils/route-guard/RouteAccessCheck';

// render - Report Routes
const UpdatedInventory = Loadable(lazy(() => import('pages/reports/updatedInventory')));
const LocationWiseInventory = Loadable(lazy(() => import('pages/reports/locationWiseInventory')));
const DamageInventory = Loadable(lazy(() => import('pages/reports/damageInventory')));

// ==============================|| MAIN ROUTING ||============================== //

const ReportRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'wms',
          children: [
            {
              path: 'reports',
              children: [
                {
                  path: 'updated-inventory',
                  element: (
                    <RouteAccessCheck permissionName={'Updated Inventory'}>
                      <UpdatedInventory />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'location-wise-inventory',
                  element: (
                    <RouteAccessCheck permissionName={'Location Wise Inventory'}>
                      <LocationWiseInventory />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'damage-inventory',
                  element: (
                    <RouteAccessCheck permissionName={'Damage Inventory'}>
                      <DamageInventory />
                    </RouteAccessCheck>
                  )
                }
              ]
            }
          ]
        },
        {
          path: 'oms',
          children: [
            {
              path: 'reports',
              children: [
                {
                  children: [
                    {
                      path: 'updated-inventory',
                      element: (
                        <RouteAccessCheck permissionName={'Updated Inventory'}>
                          <UpdatedInventory />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'location-wise-inventory',
                      element: (
                        <RouteAccessCheck permissionName={'Location Wise Inventory'}>
                          <LocationWiseInventory />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'damage-inventory',
                      element: (
                        <RouteAccessCheck permissionName={'Damage Inventory'}>
                          <DamageInventory />
                        </RouteAccessCheck>
                      )
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default ReportRoutes;
