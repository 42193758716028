import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import ClientRoutes from './ClientRoutes';
import TransactionRoutes from './TransactionRoutes';
import SetupRoutes from './SetupRoutes';
import ReportRoutes from './ReportRoutes';
import WarehouseRoutes from './WarehouseRoutes';
import GeneralRoutes from './GeneralRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([LoginRoutes, MainRoutes, ClientRoutes, TransactionRoutes, SetupRoutes, ReportRoutes, WarehouseRoutes, GeneralRoutes]);
}
