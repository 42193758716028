import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

const addVendor = async (vendorData) => {
  const config = {
    method: 'post',
    url: API_URL + 'vendor/add',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: vendorData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchVendorList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `vendor/list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const vendorStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `vendor/status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchVendorDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `vendor/get_single`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateVendor = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'vendor/edit',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchAllItemsList = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/all_item_list`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addCatalogue = async (catalogueData) => {
  const config = {
    method: 'post',
    url: API_URL + 'vendor/add_catalogue',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: catalogueData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchVendorCatalogue = async (page, limit, orgId, vendorId) => {
  let pageNum = Number(page);
  let limitNum = Number(limit);
  const config = {
    method: 'post',
    url: API_URL + `vendor/catalogue?page=${pageNum}&limit=${limitNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      orgId: orgId,
      vendorId: vendorId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const catalogueStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `vendor/catalogue_status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addVendorAddress = async (vendorAddressData) => {
  const config = {
    method: 'post',
    url: API_URL + 'vendor/add_address',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: vendorAddressData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchVendorAddressList = async (page, vendorId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `vendor/list_address?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      vendorId: vendorId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchVendorAddressDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `vendor/address_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateVendorAddress = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'vendor/update_address',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const vendorService = {
  addVendor,
  fetchVendorList,
  vendorStatus,
  fetchVendorDetail,
  updateVendor,
  fetchAllItemsList,
  addCatalogue,
  fetchVendorCatalogue,
  catalogueStatus,
  addVendorAddress,
  fetchVendorAddressList,
  fetchVendorAddressDetail,
  updateVendorAddress
};

export default vendorService;
