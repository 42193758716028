import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import warehousesetupService from './warehousesetupService';

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: '',
  zone: null,
  zones: null,
  palletorrack: null,
  palletsorracks: null,
  row: null,
  rows: null,
  location: null,
  locations: null,
  bins: null
};

export const addZone = createAsyncThunk('warehouse/add_zone', async (zoneData, thunkAPI) => {
  try {
    return await warehousesetupService.addZone(zoneData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchZoneDetail = createAsyncThunk('warehouse/zone_details', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.fetchZoneDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateZone = createAsyncThunk('warehouse/update_zone', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.updateZone(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchZoneList = createAsyncThunk('warehouse/list_zone', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await warehousesetupService.fetchZoneList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const zoneStatus = createAsyncThunk('warehouse/zone_status', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.zoneStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addPalletRack = createAsyncThunk('warehouse/add_pallet_rack', async (palletRackData, thunkAPI) => {
  try {
    return await warehousesetupService.addPalletRack(palletRackData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchPalletRackDetail = createAsyncThunk('warehouse/pallet_rack_details', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.fetchPalletRackDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updatePalletRack = createAsyncThunk('warehouse/update_pallet_rack', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.updatePalletRack(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchPalletRackList = createAsyncThunk('warehouse/list_pallet_rack', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await warehousesetupService.fetchPalletRackList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const palletRackStatus = createAsyncThunk('warehouse/pallet_rack_status', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.palletRackStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addRow = createAsyncThunk('warehouse/add_warehouse_row', async (rowData, thunkAPI) => {
  try {
    return await warehousesetupService.addRow(rowData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchRowDetail = createAsyncThunk('warehouse/row_details', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.fetchRowDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateRow = createAsyncThunk('warehouse/update_row', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.updateRow(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchRowList = createAsyncThunk('warehouse/warehouse_row_list', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await warehousesetupService.fetchRowList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const rowStatus = createAsyncThunk('warehouse/warehouse_row_status', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.rowStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchBinFormData = createAsyncThunk('warehouse/add_bin_form_data', async (organizationId, thunkAPI) => {
  try {
    return await warehousesetupService.fetchBinFormData(organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addBinLocation = createAsyncThunk('warehouse/add_bin_location', async (binLocationData, thunkAPI) => {
  try {
    return await warehousesetupService.addBinLocation(binLocationData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchBinLocationList = createAsyncThunk('warehouse/bin_location_list', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await warehousesetupService.fetchBinLocationList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const binLocationStatus = createAsyncThunk('warehouse/bin_location_status', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.binLocationStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchBinLocationSerialization = createAsyncThunk('warehouse/bin_location_serialization', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.fetchBinLocationSerialization(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const binSerializationUpdate = createAsyncThunk('warehouse/bin_serialization_update', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.binSerializationUpdate(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchAllAvailableBins = createAsyncThunk('warehouse/all_binLocations', async (data, thunkAPI) => {
  try {
    return await warehousesetupService.fetchAllAvailableBins(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const warehousesetupSlice = createSlice({
  name: 'warehousesetup',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.isSuccess = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addZone.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addZone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.zone = action.payload;
      })
      .addCase(addZone.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchZoneDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchZoneDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.zone = action.payload;
      })
      .addCase(fetchZoneDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateZone.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateZone.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.zone = action.payload;
      })
      .addCase(updateZone.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchZoneList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchZoneList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.zones = action.payload;
      })
      .addCase(fetchZoneList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(zoneStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(zoneStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.zone = action.payload;
      })
      .addCase(zoneStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addPalletRack.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPalletRack.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.palletorrack = action.payload;
      })
      .addCase(addPalletRack.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPalletRackDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPalletRackDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.palletorrack = action.payload;
      })
      .addCase(fetchPalletRackDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updatePalletRack.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePalletRack.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.palletorrack = action.payload;
      })
      .addCase(updatePalletRack.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPalletRackList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPalletRackList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.palletsorracks = action.payload;
      })
      .addCase(fetchPalletRackList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(palletRackStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(palletRackStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.palletorrack = action.payload;
      })
      .addCase(palletRackStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addRow.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRow.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.row = action.payload;
      })
      .addCase(addRow.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchRowDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRowDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.row = action.payload;
      })
      .addCase(fetchRowDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateRow.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRow.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.row = action.payload;
      })
      .addCase(updateRow.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchRowList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRowList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rows = action.payload;
      })
      .addCase(fetchRowList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(rowStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(rowStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.row = action.payload;
      })
      .addCase(rowStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchBinFormData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBinFormData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.location = action.payload;
      })
      .addCase(fetchBinFormData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addBinLocation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBinLocation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.location = action.payload;
      })
      .addCase(addBinLocation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchBinLocationList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBinLocationList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.locations = action.payload;
      })
      .addCase(fetchBinLocationList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(binLocationStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(binLocationStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.location = action.payload;
      })
      .addCase(binLocationStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchBinLocationSerialization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBinLocationSerialization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.locations = action.payload;
      })
      .addCase(fetchBinLocationSerialization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(binSerializationUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(binSerializationUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.locations = action.payload;
      })
      .addCase(binSerializationUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchAllAvailableBins.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllAvailableBins.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.bins = action.payload;
      })
      .addCase(fetchAllAvailableBins.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = warehousesetupSlice.actions;

export default warehousesetupSlice.reducer;
