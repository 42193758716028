// React Import
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// material-ui
import { Grid, Stack, InputLabel, Button, Typography, Autocomplete, TextField, CircularProgress } from '@mui/material';

// project import
import { Formik } from 'formik';
import * as Yup from 'yup';
import AnimateButton from 'components/@extended/AnimateButton';
import { fetchFilteredWarehouse, reset } from 'store/reducers/features/authentication/authenticationSlice';

// ==============================|| Warehouse & Customer Filter ||============================== //

const ClientFilter = (props) => {
  const { onCancel, onFilterChange } = props;
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);
  const { warehouses, isError, message } = useSelector((state) => state.authentication);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    const data = {
      clientId: user.organizationId,
      organizationSubType: user.organizationSubType,
      userType: user.userType
    };
    if (isError) {
      console.log(message);
    }
    dispatch(fetchFilteredWarehouse(data));
    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch, user.organizationId, user.organizationSubType, user.userType]);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const clientFilteredData = JSON.parse(localStorage.getItem('clientFilteredData'));

  return (
    <>
      <Formik
        initialValues={{
          warehouse: clientFilteredData ? clientFilteredData.warehouse : []
        }}
        validationSchema={Yup.object().shape({
          warehouse: Yup.object().nullable()
        })}
        onSubmit={async (values) => {
          localStorage.setItem('clientFilteredData', JSON.stringify(values));
          isMounted && onCancel(), onFilterChange();
        }}
      >
        {({ setFieldValue, handleBlur, handleChange, handleSubmit, isSubmitting, values, resetForm }) => (
          <form noValidate autoComplete="off" onSubmit={handleSubmit} onReset={resetForm}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h5" component="div">
                    Warehouse & Customer Filter
                  </Typography>
                </Stack>
                <hr></hr>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={1}>
                  <InputLabel id="warehouse">Warehouse</InputLabel>
                  <Autocomplete
                    multiple
                    size="small"
                    id="warehouse"
                    options={warehouses}
                    getOptionLabel={(option) => option.warehouseDisplayName}
                    value={values.warehouse}
                    name="warehouse"
                    onChange={(e, data) => {
                      handleChange;
                      setFieldValue('warehouse', data);
                    }}
                    onBlur={handleBlur}
                    isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
                    renderInput={(params) => <TextField {...params} placeholder="Select Warehouse" />}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        p: 1
                      },
                      '& .MuiAutocomplete-tag': {
                        bgcolor: 'primary.lighter',
                        border: '1px solid',
                        borderColor: 'primary.light',
                        '& .MuiSvgIcon-root': {
                          color: 'primary.main',
                          '&:hover': {
                            color: 'primary.dark'
                          }
                        }
                      }
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end">
                  <AnimateButton>
                    <Button
                      size="small"
                      sx={{ my: 2 }}
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {isSubmitting && <CircularProgress size={15} thickness={4} />}
                      Search
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ClientFilter;
ClientFilter.propTypes = {
  onCancel: PropTypes.func,
  onFilterChange: PropTypes.func
};
