// react import
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import axios from 'axios';
import { openSnackbar } from 'store/reducers/snackbar';
import { useDispatch } from 'react-redux';

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);
const API_URL = process.env.REACT_APP_LIVE_API_URL;
// const API_URL = process.env.REACT_APP_API_URL;

export const JWTProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [state, authDispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          await axios
            .post(API_URL + 'user/validate_token')
            .then((response) => {
              const user = response.data.data;
              if (response.data.status === 1) {
                window.localStorage.setItem('user', JSON.stringify(user));
                authDispatch({
                  type: LOGIN,
                  payload: {
                    isLoggedIn: true,
                    user: response.data.data.user
                  }
                });
              } else if (response.data.status === 0) {
                localStorage.clear();
                authDispatch({ type: LOGOUT });
                dispatch(
                  openSnackbar({
                    open: true,
                    message: `${response.data.errors[0].msg}`,
                    variant: 'alert',
                    alert: {
                      color: 'error'
                    },
                    close: true
                  })
                );
              }
            })
            .catch((error) => {
              if (error.response && error.response.status === 401) {
                authDispatch({ type: LOGOUT });
                dispatch(
                  openSnackbar({
                    open: true,
                    message: `${error.response.data.errors[0].msg}`,
                    variant: 'alert',
                    alert: {
                      color: 'error'
                    },
                    close: true
                  })
                );
              } else {
                authDispatch({ type: LOGOUT });
                dispatch(
                  openSnackbar({
                    open: true,
                    message: `${error.message}`,
                    variant: 'alert',
                    alert: {
                      color: 'error'
                    },
                    close: true
                  })
                );
              }
            });
        } else {
          authDispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Token has expired. Please login again',
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: true
          })
        );
        authDispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, [dispatch]);

  const login = async (email, password) => {
    const data = {
      email: email,
      password: password
    };
    await axios
      .post(API_URL + 'user/login', data)
      .then((response) => {
        if (response.data.status === 1) {
          const user = response.data.user;
          const serviceToken = response.data.user.token;
          window.localStorage.setItem('user', JSON.stringify(user));
          setSession(serviceToken);
          authDispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
          dispatch(
            openSnackbar({
              open: true,
              message: `${response.data.message}`,
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: true
            })
          );
        } else if (response.data.status === 0) {
          dispatch(
            openSnackbar({
              open: true,
              message: `${response.data.message}`,
              variant: 'alert',
              alert: {
                color: 'error'
              },
              close: true
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/account/register', {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    // window.localStorage.removeItem('user');
    window.localStorage.clear();
    authDispatch({ type: LOGOUT });
  };

  const resetPassword = async () => {};

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
