import home from './home';
import general from './general';
import master from './master';
import transactions from './transactions';
import setup from './setup';
import report from './report';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [home, transactions, master, report, setup, general]
};
export default menuItems;
