import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

const fetchPoVendorList = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `vendor/get_all`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchWmsSetup = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `client_setup/process_type_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchAllItems = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/all_item_list`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const createPo = async (poData) => {
  const config = {
    method: 'post',
    url: API_URL + 'transaction/create_po',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: poData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchPoList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `transaction/list_all_po?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchPoDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/po_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const poConfirmation = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/po_confirmation`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updatePo = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'transaction/edit_po',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const createDraft = async (draftData) => {
  const config = {
    method: 'post',
    url: API_URL + 'common/create_draft',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: draftData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchDraftList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `common/draft_list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchDraftDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `common/get_draft_details`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateDraft = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'common/update_draft',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const deleteDraft = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'common/delete_draft',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchPoLogList = async (page, organizationId, logData) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `logs/order_logs_list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId,
      logData: logData
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchPoVendorAddressList = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `vendor/po_vendor_address_list`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const createPoInvoice = async (invoiceData) => {
  const config = {
    method: 'post',
    url: API_URL + 'transaction/create_invoice',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: invoiceData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchInvoiceList = async (page, organizationId, warehouseId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `transaction/invoice_list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId,
      warehouseId: warehouseId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchInvoiceDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/invoice_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateInvoice = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'transaction/edit_invoice',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchPoInvoiceList = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/po_invoice_list`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchPoInvoiceDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/po_invoice_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchPoInvoiceLotDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/po_invoice_lot_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const invoiceMismatchProcess = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/invoice_mismatch_process`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const grnCompleteFromExcel = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/grn_complete_from_excel`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchStockTransferItemDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/stock_transfer_item_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const createStn = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'transaction/create_stn',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchReceiveList = async (page, organizationId, warehouseId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `transaction/receiving_list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId,
      warehouseId: warehouseId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchCreateLotDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/create_lot_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const lotReceiving = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/lot_receiving`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchLotList = async (page, organizationId, warehouseId, lotStage) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `transaction/lot_list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId,
      warehouseId: warehouseId,
      lotStage: lotStage
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchReceiveLotDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/receive_lot_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const createQc = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/create_qc`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchPutAwayLotDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/putaway_lot_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchBinLocations = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/get_bin_location`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const createPutaway = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/create_putaway`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const cancelLot = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/cancel_lot`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const completeGrn = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/complete_grn`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchStnOutOrderDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `transaction/stn_out_order_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const inwardService = {
  fetchPoVendorList,
  fetchWmsSetup,
  fetchAllItems,
  createPo,
  fetchPoList,
  fetchPoDetail,
  poConfirmation,
  updatePo,
  createDraft,
  fetchDraftList,
  fetchDraftDetail,
  updateDraft,
  deleteDraft,
  fetchPoLogList,
  fetchPoVendorAddressList,
  createPoInvoice,
  fetchInvoiceList,
  fetchInvoiceDetail,
  updateInvoice,
  fetchPoInvoiceList,
  fetchPoInvoiceDetail,
  fetchPoInvoiceLotDetail,
  invoiceMismatchProcess,
  grnCompleteFromExcel,
  fetchStockTransferItemDetail,
  createStn,
  fetchReceiveList,
  fetchCreateLotDetail,
  lotReceiving,
  fetchLotList,
  fetchReceiveLotDetail,
  createQc,
  fetchPutAwayLotDetail,
  fetchBinLocations,
  createPutaway,
  cancelLot,
  completeGrn,
  fetchStnOutOrderDetail
};

export default inwardService;
