import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import ProductLayout from 'layout/CommonLayout/productIndex';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteAccessCheck from 'utils/route-guard/RouteAccessCheck';

//render - product Page
const AssignedProducts = Loadable(lazy(() => import('pages/extra-pages/assignedProducts')));
// render - Dashboard Pages
const OmsDashboard = Loadable(lazy(() => import('pages/dashboard/omsdashboard')));
const WmsDashboard = Loadable(lazy(() => import('pages/dashboard/wmsdashboard')));
const TmsDashboard = Loadable(lazy(() => import('pages/dashboard/tmsdashboard')));
// render - User Profile
const UserProfile = Loadable(lazy(() => import('pages/apps/profiles/user')));
const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));
// render - Warehouse Master
const AddWarehouse = Loadable(lazy(() => import('pages/admin/masters/warehouse/addwarehouse')));
const WarehouseTabInfo = Loadable(lazy(() => import('sections/admin/masters/warehouse/info/index')));
const WarehouseTabOther = Loadable(lazy(() => import('sections/admin/masters/warehouse/otherinfo/TabWhOther')));
const WarehouseTabDocument = Loadable(lazy(() => import('sections/admin/masters/warehouse/document/TabWhDocument')));
const WarehouseTabImage = Loadable(lazy(() => import('sections/admin/masters/warehouse/image/TabWhImage')));
const ListWarehouse = Loadable(lazy(() => import('pages/admin/masters/warehouse/listwarehouse')));
// render - Client Master
const ClientList = Loadable(lazy(() => import('pages/admin/masters/client/client-list')));
const Registration = Loadable(lazy(() => import('pages/admin/masters/client/registration')));
// ============================== profile tabs ============================= //
const Profile = Loadable(lazy(() => import('pages/admin/masters/client/tabs/Profile')));
const Basic = Loadable(lazy(() => import('sections/admin/masters/client/registration/profile/Basic')));
const Address = Loadable(lazy(() => import('sections/admin/masters/client/registration/profile/Address')));
const Contact = Loadable(lazy(() => import('sections/admin/masters/client/registration/profile/Contact')));
const Documents = Loadable(lazy(() => import('sections/admin/masters/client/registration/profile/Documents')));
const Bank = Loadable(lazy(() => import('sections/admin/masters/client/registration/profile/Bank')));
// ============================== spacebooking tabs ============================= //
const SpaceBooking = Loadable(lazy(() => import('pages/admin/masters/client/tabs/SpaceBooking')));
const Booking = Loadable(lazy(() => import('sections/admin/masters/client/registration/space-booking/booking/Booking')));
const BookingList = Loadable(lazy(() => import('sections/admin/masters/client/registration/space-booking/booking/BookingList')));
const Capex = Loadable(lazy(() => import('sections/admin/masters/client/registration/space-booking/Capex')));
const Opex = Loadable(lazy(() => import('sections/admin/masters/client/registration/space-booking/Opex')));
const ManPower = Loadable(lazy(() => import('sections/admin/masters/client/registration/space-booking/ManPower')));
const Spoc = Loadable(lazy(() => import('sections/admin/masters/client/registration/space-booking/Spoc')));
// ============================== wmssetup tabs ================================= //
const WmsSetup = Loadable(lazy(() => import('pages/admin/masters/client/tabs/WmsSetup')));
const General = Loadable(lazy(() => import('sections/admin/masters/client/registration/wms-setup/General')));
const Inward = Loadable(lazy(() => import('sections/admin/masters/client/registration/wms-setup/Inward')));
const Outward = Loadable(lazy(() => import('sections/admin/masters/client/registration/wms-setup/Outward')));
const CustomerReturnSetup = Loadable(lazy(() => import('sections/admin/masters/client/registration/wms-setup/CustomerReturnSetup')));

// render - Warehouse Partner
const WarehousePartnerList = Loadable(lazy(() => import('pages/admin/masters/warehousepartner/warehousepartner-list')));
const WarehousePartnerRegistration = Loadable(lazy(() => import('pages/admin/masters/warehousepartner/registration')));
// ============================== profile tabs ============================= //
const WarehousePartnerProfile = Loadable(lazy(() => import('pages/admin/masters/warehousepartner/tabs/Profile')));
const WarehousePartnerBasic = Loadable(lazy(() => import('sections/admin/masters/warehouse_partner/registration/profile/Basic')));
const WarehousePartnerAddress = Loadable(lazy(() => import('sections/admin/masters/warehouse_partner/registration/profile/Address')));
const WarehousePartnerContact = Loadable(lazy(() => import('sections/admin/masters/warehouse_partner/registration/profile/Contact')));
const WarehousePartnerDocuments = Loadable(lazy(() => import('sections/admin/masters/warehouse_partner/registration/profile/Documents')));
const WarehousePartnerBank = Loadable(lazy(() => import('sections/admin/masters/warehouse_partner/registration/profile/Bank')));

// ============================= Invoice tabs =================================== //
const Invoice = Loadable(lazy(() => import('pages/admin/masters/client/tabs/Invoice')));
// ============================= wmsplan tabs =================================== //
const WmsPlan = Loadable(lazy(() => import('pages/admin/masters/client/tabs/WmsPlan')));
const NotFoundPage = Loadable(lazy(() => import('pages/maintenance/404')));
const Unauthorized = Loadable(lazy(() => import('pages/maintenance/unauthorized')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'wms',
          children: [
            {
              path: 'dashboard',
              element: <WmsDashboard />
            },
            {
              path: 'master',
              children: [
                {
                  path: 'warehouse',
                  children: [
                    {
                      path: 'add-warehouse',
                      element: (
                        <RouteAccessCheck permissionName={'Warehouse Create'}>
                          <AddWarehouse />
                        </RouteAccessCheck>
                      ),
                      children: [
                        {
                          path: 'basic',
                          element: <WarehouseTabInfo />
                        },
                        {
                          path: 'other',
                          element: <WarehouseTabOther />
                        },
                        {
                          path: 'document',
                          element: <WarehouseTabDocument />
                        },
                        {
                          path: 'image',
                          element: <WarehouseTabImage />
                        }
                      ]
                    },
                    {
                      path: 'edit-warehouse',
                      element: (
                        <RouteAccessCheck permissionName={'Warehouse View'}>
                          <AddWarehouse />
                        </RouteAccessCheck>
                      ),
                      children: [
                        {
                          path: 'basic',
                          element: <WarehouseTabInfo />
                        },
                        {
                          path: 'other',
                          element: <WarehouseTabOther />
                        },
                        {
                          path: 'document',
                          element: <WarehouseTabDocument />
                        },
                        {
                          path: 'image',
                          element: <WarehouseTabImage />
                        }
                      ]
                    },
                    {
                      path: 'list-warehouse',
                      element: (
                        <RouteAccessCheck permissionName={'Warehouse List'}>
                          <ListWarehouse />
                        </RouteAccessCheck>
                      )
                    }
                  ]
                }
              ]
            },
            {
              path: 'maintenance/unauthorized',
              element: <Unauthorized />
            }
          ]
        },
        {
          path: 'oms',
          children: [
            {
              path: 'dashboard',
              element: <OmsDashboard />
            },
            {
              path: 'master',
              children: [
                {
                  path: 'client',
                  children: [
                    {
                      path: 'client-list',
                      element: (
                        <RouteAccessCheck permissionName={'Client List'}>
                          <ClientList />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'registration',
                      element: (
                        <RouteAccessCheck permissionName={'Client View'}>
                          <Registration />
                        </RouteAccessCheck>
                      ),
                      children: [
                        {
                          path: 'profile',
                          element: (
                            <RouteAccessCheck permissionName={'Client View'}>
                              <Profile />
                            </RouteAccessCheck>
                          ),
                          children: [
                            {
                              path: 'basic',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <Basic />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'address',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <Address />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'contact',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <Contact />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'documents',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <Documents />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'bank',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <Bank />
                                </RouteAccessCheck>
                              )
                            }
                          ]
                        },
                        {
                          path: 'space-booking',
                          element: (
                            <RouteAccessCheck permissionName={'Client View'}>
                              <SpaceBooking />
                            </RouteAccessCheck>
                          ),
                          children: [
                            {
                              path: 'booking',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <Booking />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'list-booking',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <BookingList />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'capex',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <Capex />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'opex',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <Opex />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'man-power',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <ManPower />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'spoc',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <Spoc />
                                </RouteAccessCheck>
                              )
                            }
                          ]
                        },
                        {
                          path: 'wms-setup',
                          element: (
                            <RouteAccessCheck permissionName={'Client View'}>
                              <WmsSetup />
                            </RouteAccessCheck>
                          ),
                          children: [
                            {
                              path: 'general',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <General />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'inward',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <Inward />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'outward',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <Outward />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'customer-return',
                              element: (
                                <RouteAccessCheck permissionName={'Client View'}>
                                  <CustomerReturnSetup />
                                </RouteAccessCheck>
                              )
                            }
                          ]
                        },
                        {
                          path: 'invoice',
                          element: (
                            <RouteAccessCheck permissionName={'Client View'}>
                              <Invoice />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'wms-plan',
                          element: (
                            <RouteAccessCheck permissionName={'Client View'}>
                              <WmsPlan />
                            </RouteAccessCheck>
                          )
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'warehousepartner',
                  children: [
                    {
                      path: 'warehouse-partner-list',
                      element: (
                        <RouteAccessCheck permissionName={'Warehouse Partner List'}>
                          <WarehousePartnerList />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'registration',
                      element: (
                        <RouteAccessCheck permissionName={'Warehouse Partner View'}>
                          <WarehousePartnerRegistration />
                        </RouteAccessCheck>
                      ),
                      children: [
                        {
                          path: 'profile',
                          element: (
                            <RouteAccessCheck permissionName={'Warehouse Partner View'}>
                              <WarehousePartnerProfile />
                            </RouteAccessCheck>
                          ),
                          children: [
                            {
                              path: 'basic',
                              element: (
                                <RouteAccessCheck permissionName={'Warehouse Partner View'}>
                                  <WarehousePartnerBasic />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'address',
                              element: (
                                <RouteAccessCheck permissionName={'Warehouse Partner View'}>
                                  <WarehousePartnerAddress />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'contact',
                              element: (
                                <RouteAccessCheck permissionName={'Warehouse Partner View'}>
                                  <WarehousePartnerContact />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'documents',
                              element: (
                                <RouteAccessCheck permissionName={'Warehouse Partner View'}>
                                  <WarehousePartnerDocuments />
                                </RouteAccessCheck>
                              )
                            },
                            {
                              path: 'bank',
                              element: (
                                <RouteAccessCheck permissionName={'Warehouse Partner View'}>
                                  <WarehousePartnerBank />
                                </RouteAccessCheck>
                              )
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              path: 'maintenance/unauthorized',
              element: <Unauthorized />
            }
          ]
        },
        {
          path: 'tms',
          children: [
            {
              path: 'dashboard',
              element: <TmsDashboard />
            }
          ]
        },
        {
          path: 'user',
          element: <UserProfile />,
          children: [
            {
              path: 'personal',
              element: <UserTabPersonal />
            },
            {
              path: 'payment',
              element: <UserTabPayment />
            },
            {
              path: 'password',
              element: <UserTabPassword />
            },
            {
              path: 'settings',
              element: <UserTabSettings />
            }
          ]
        }
      ]
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <ProductLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'assigned-products',
          element: <AssignedProducts />
        },
        {
          path: '*',
          element: <NotFoundPage />
        }
      ]
    }
  ]
};

export default MainRoutes;
