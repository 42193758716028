// React Import
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// material-ui
import { Grid, Stack, InputLabel, Button, Typography, Autocomplete, TextField, CircularProgress, LinearProgress } from '@mui/material';

// project import
import { Formik } from 'formik';
import * as Yup from 'yup';
import AnimateButton from 'components/@extended/AnimateButton';
import { fetchWhPartnerFilteredWarehouse, fetchWmsFilteredClient, reset } from 'store/reducers/features/authentication/authenticationSlice';

// ==============================|| Warehouse & Customer Filter ||============================== //

const WarehousePartnerFilter = (props) => {
  const { onCancel, onFilterChange } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [clientList, setClientList] = useState([]);
  const { warehouses, isError, message } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (isError) {
      console.log(message);
    }
    dispatch(fetchWhPartnerFilteredWarehouse());
    return () => {
      dispatch(reset());
    };
  }, [isError, message, dispatch]);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  //Fetch Warehouse
  function fetchClient(value) {
    setLoading(true);
    const data = {
      warehouseId: value
    };
    dispatch(fetchWmsFilteredClient(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          setClientList(res.data);
          localStorage.setItem('clientList', JSON.stringify(res.data));
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log('Error fetching permissions:', e);
        setLoading(false);
      });
  }

  const whPartnerFilteredData = JSON.parse(localStorage.getItem('whPartnerFilteredData'));
  const clients = JSON.parse(localStorage.getItem('clientList'));

  return (
    <>
      <Formik
        initialValues={{
          warehouse: whPartnerFilteredData ? whPartnerFilteredData.warehouse : null,
          client: whPartnerFilteredData ? whPartnerFilteredData.client : null
        }}
        validationSchema={Yup.object().shape({
          warehouse: Yup.object().nullable(),
          client: Yup.object().nullable()
        })}
        onSubmit={async (values) => {
          values.warehouse === null && localStorage.removeItem('clientList');
          localStorage.setItem('whPartnerFilteredData', JSON.stringify(values));
          isMounted && onCancel(), onFilterChange();
        }}
      >
        {({ setFieldValue, handleChange, handleBlur, handleSubmit, isSubmitting, values, resetForm }) => (
          <form noValidate autoComplete="off" onSubmit={handleSubmit} onReset={resetForm}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h5" component="div">
                    Warehouse & Customer Filter
                  </Typography>
                </Stack>
                <hr></hr>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={1}>
                  <InputLabel id="warehouse">Warehouse</InputLabel>
                  <Autocomplete
                    id="warehouse"
                    size="small"
                    name="warehouse"
                    fullWidth
                    options={warehouses}
                    autoHighlight
                    value={values.warehouse}
                    getOptionLabel={(option) => option.warehouseDisplayName}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    onChange={(e, data) => {
                      if (data) {
                        setFieldValue('client', null);
                        fetchClient(data.warehouseId);
                      } else {
                        setFieldValue('client', null);
                      }
                      setFieldValue('warehouse', data);
                    }}
                    renderInput={(params) => <TextField name="warehouse" {...params} placeholder="Select Warehouse" />}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        p: 1
                      },
                      '& .MuiAutocomplete-tag': {
                        bgcolor: 'primary.lighter',
                        border: '1px solid',
                        borderColor: 'primary.light',
                        '& .MuiSvgIcon-root': {
                          color: 'primary.main',
                          '&:hover': {
                            color: 'primary.dark'
                          }
                        }
                      }
                    }}
                  />
                </Stack>
              </Grid>
              {!loading ? (
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                    <InputLabel id="client">Client</InputLabel>
                    <Autocomplete
                      id="client"
                      size="small"
                      name="client"
                      fullWidth
                      options={clients ? clients : clientList}
                      autoHighlight
                      value={values.client}
                      getOptionLabel={(option) => option.clientName}
                      isOptionEqualToValue={(option, value) => option._id === value._id}
                      onChange={(e, data) => {
                        handleChange;
                        setFieldValue('client', data);
                      }}
                      onBlur={handleBlur}
                      disabled={values.warehouse === null ? true : false}
                      renderInput={(params) => <TextField name="client" {...params} placeholder="Select Client" />}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          p: 1
                        },
                        '& .MuiAutocomplete-tag': {
                          bgcolor: 'primary.lighter',
                          border: '1px solid',
                          borderColor: 'primary.light',
                          '& .MuiSvgIcon-root': {
                            color: 'primary.main',
                            '&:hover': {
                              color: 'primary.dark'
                            }
                          }
                        }
                      }}
                    />
                  </Stack>
                </Grid>
              ) : (
                <>
                  <LinearProgress />
                </>
              )}
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end">
                  <AnimateButton>
                    <Button
                      size="small"
                      sx={{ my: 2 }}
                      disableElevation
                      disabled={isSubmitting}
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {isSubmitting && <CircularProgress size={15} thickness={4} />}
                      Search
                    </Button>
                  </AnimateButton>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default WarehousePartnerFilter;
WarehousePartnerFilter.propTypes = {
  onCancel: PropTypes.func,
  onFilterChange: PropTypes.func
};
