import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

const fetchDepsOrderList = async (page, organizationId, spaceBookingId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `deps/deps_order_list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId,
      spaceBookingId: spaceBookingId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const markDamage = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `deps/mark_damage`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const markFaulty = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `deps/mark_faulty`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const markGood = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `deps/mark_good`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const createRepairAndDisposeOrder = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `deps/create_repair_and_dispose_order`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchDepsDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `deps/view_order`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const depService = {
  fetchDepsOrderList,
  markDamage,
  markFaulty,
  markGood,
  createRepairAndDisposeOrder,
  fetchDepsDetail
};

export default depService;
