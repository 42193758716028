import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import itemService from './itemService';

const initialState = {
  items: null,
  item: null,
  category: null,
  groupItem: null,
  subCategory: null,
  brand: null,
  uom: null,
  manufacturer: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: '',
  states: null,
  price: null,
  import: null
};

// Add Item Category
export const addCategory = createAsyncThunk('item/add_category', async (categoryData, thunkAPI) => {
  try {
    return await itemService.addCategory(categoryData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Category List
export const fetchCategoryList = createAsyncThunk('item/list_category', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await itemService.fetchCategoryList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Category Status Toggle
export const categoryStatus = createAsyncThunk('item/update_category_status', async (data, thunkAPI) => {
  try {
    return await itemService.categoryStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Single Category Detail
export const fetchCategoryDetail = createAsyncThunk('item/category_detail', async (data, thunkAPI) => {
  try {
    return await itemService.fetchCategoryDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Category Updation
export const updateCategory = createAsyncThunk('item/edit_category', async (data, thunkAPI) => {
  try {
    return await itemService.updateCategory(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Active Category List
export const fetchCategory = createAsyncThunk('item/list_all_category', async (data, thunkAPI) => {
  try {
    return await itemService.fetchCategory(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add Item Sub Category
export const addSubCategory = createAsyncThunk('item/add_sub_category', async (subCategoryData, thunkAPI) => {
  try {
    return await itemService.addSubCategory(subCategoryData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Sub Category List
export const fetchSubCategoryList = createAsyncThunk('item/list_sub_category', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await itemService.fetchSubCategoryList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Sub Category Status Toggle
export const subCategoryStatus = createAsyncThunk('item/update_sub_category_status', async (data, thunkAPI) => {
  try {
    return await itemService.subCategoryStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Single Sub Category Detail
export const fetchSubCategoryDetail = createAsyncThunk('item/sub_category_detail', async (data, thunkAPI) => {
  try {
    return await itemService.fetchSubCategoryDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Sub Category Updation
export const updateSubCategory = createAsyncThunk('item/edit_sub_category', async (data, thunkAPI) => {
  try {
    return await itemService.updateSubCategory(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add Brand
export const addBrand = createAsyncThunk('item/add_brand', async (subCategoryData, thunkAPI) => {
  try {
    return await itemService.addBrand(subCategoryData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Brand List
export const fetchBrandList = createAsyncThunk('item/list_brand', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await itemService.fetchBrandList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Brand Status Toggle
export const brandStatus = createAsyncThunk('item/update_brand_status', async (data, thunkAPI) => {
  try {
    return await itemService.brandStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Single Brand Detail
export const fetchBrandDetail = createAsyncThunk('item/brand_detail', async (data, thunkAPI) => {
  try {
    return await itemService.fetchBrandDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Brand Updation
export const updateBrand = createAsyncThunk('item/edit_brand', async (data, thunkAPI) => {
  try {
    return await itemService.updateBrand(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Active Brand List
export const fetchBrand = createAsyncThunk('item/list_all_brand', async (data, thunkAPI) => {
  try {
    return await itemService.fetchBrand(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch All Sub Category
export const fetchAllSubCategory = createAsyncThunk('item/list_all_sub_category', async (data, thunkAPI) => {
  try {
    return await itemService.fetchAllSubCategory(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch All UOM
export const fetchAllUom = createAsyncThunk('common_setup/all_uom', async (thunkAPI) => {
  try {
    return await itemService.fetchAllUom();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add Item
export const addItem = createAsyncThunk('item/add_item', async (data, thunkAPI) => {
  try {
    return await itemService.addItem(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Item List
export const fetchItemsList = createAsyncThunk('item/item_list', async ({ pageNum, organizationId, organizationType }, thunkAPI) => {
  try {
    return await itemService.fetchItemsList(pageNum, organizationId, organizationType);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Item Status Toggle
export const itemStatus = createAsyncThunk('item/update_item_status', async (data, thunkAPI) => {
  try {
    return await itemService.itemStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Single Item Detail
export const fetchItemDetail = createAsyncThunk('item/item_detail', async (data, thunkAPI) => {
  try {
    return await itemService.fetchItemDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Edit Item Detail
export const editItem = createAsyncThunk('item/edit_item', async (data, thunkAPI) => {
  try {
    return await itemService.editItem(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add Manufacturer
export const addManufacturer = createAsyncThunk('item/add_manufacturer', async (subCategoryData, thunkAPI) => {
  try {
    return await itemService.addManufacturer(subCategoryData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Manufacturer List
export const fetchManufacturerList = createAsyncThunk('item/list_manufacturer', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await itemService.fetchManufacturerList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Manufacturer Status Toggle
export const manufacturerStatus = createAsyncThunk('item/update_manufacturer_status', async (data, thunkAPI) => {
  try {
    return await itemService.manufacturerStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Single Manufacturer Detail
export const fetchManufacturerDetail = createAsyncThunk('item/manufacturer_detail', async (data, thunkAPI) => {
  try {
    return await itemService.fetchManufacturerDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Manufacturer Updation
export const updateManufacturer = createAsyncThunk('item/edit_manufacturer', async (data, thunkAPI) => {
  try {
    return await itemService.updateManufacturer(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Active Manufacturer List
export const fetchManufacturer = createAsyncThunk('item/list_all_manufacturer', async (data, thunkAPI) => {
  try {
    return await itemService.fetchManufacturer(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch All Item List
export const fetchAllItemList = createAsyncThunk('item/item_list_with_price', async ({ pageNum, limitNum, organizationId }, thunkAPI) => {
  try {
    return await itemService.fetchAllItemList(pageNum, limitNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch All States
export const fetchItemStateList = createAsyncThunk('item/applicable_state_list', async (thunkAPI) => {
  try {
    return await itemService.fetchItemStateList();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Item Price Detail
export const fetchItemPriceDetail = createAsyncThunk('item/single_item_price_details', async (data, thunkAPI) => {
  try {
    return await itemService.fetchItemPriceDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add or Update Item List Price
export const updateItemPrice = createAsyncThunk('item/update_single_item_price', async (itemPriceData, thunkAPI) => {
  try {
    return await itemService.updateItemPrice(itemPriceData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add Item Group
export const addItemGroup = createAsyncThunk('item/add_item_group', async (data, thunkAPI) => {
  try {
    return await itemService.addItemGroup(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchItemGroupList = createAsyncThunk('item/item_group_list', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await itemService.fetchItemGroupList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchGroupDetails = createAsyncThunk('item/group_details', async (data, thunkAPI) => {
  try {
    return await itemService.fetchGroupDetails(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteGroupItem = createAsyncThunk('item/delete_group_item', async (data, thunkAPI) => {
  try {
    return await itemService.deleteGroupItem(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const itemExcelImport = createAsyncThunk('item/import', async (formData, thunkAPI) => {
  try {
    return await itemService.itemExcelImport(formData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const itemPriceMasterExcelExport = createAsyncThunk('item/item_price_excel_export', async (formData, thunkAPI) => {
  try {
    return await itemService.itemPriceMasterExcelExport(formData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const depsItemList = createAsyncThunk('item/deps_item_list', async (data, thunkAPI) => {
  try {
    return await itemService.depsItemList(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.category = action.payload;
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchCategoryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCategoryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.category = action.payload;
      })
      .addCase(fetchCategoryList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(categoryStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(categoryStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.category = action.payload;
      })
      .addCase(categoryStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchCategoryDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCategoryDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.category = action.payload;
      })
      .addCase(fetchCategoryDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.category = action.payload;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.category = action.payload.data;
      })
      .addCase(fetchCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addSubCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addSubCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subCategory = action.payload;
      })
      .addCase(addSubCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchSubCategoryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSubCategoryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subCategory = action.payload;
      })
      .addCase(fetchSubCategoryList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(subCategoryStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(subCategoryStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subCategory = action.payload;
      })
      .addCase(subCategoryStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchSubCategoryDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSubCategoryDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subCategory = action.payload;
      })
      .addCase(fetchSubCategoryDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateSubCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSubCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subCategory = action.payload;
      })
      .addCase(updateSubCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addBrand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBrand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.brand = action.payload;
      })
      .addCase(addBrand.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchBrandList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBrandList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.brand = action.payload;
      })
      .addCase(fetchBrandList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(brandStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(brandStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.brand = action.payload;
      })
      .addCase(brandStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchBrandDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBrandDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.brand = action.payload;
      })
      .addCase(fetchBrandDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateBrand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBrand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.brand = action.payload;
      })
      .addCase(updateBrand.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchBrand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBrand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.brand = action.payload;
      })
      .addCase(fetchBrand.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchAllSubCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllSubCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subCategory = action.payload;
      })
      .addCase(fetchAllSubCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchAllUom.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllUom.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.subCategory = action.payload;
      })
      .addCase(fetchAllUom.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.item = action.payload;
      })
      .addCase(addItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchItemsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchItemsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = action.payload;
      })
      .addCase(fetchItemsList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(itemStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(itemStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.item = action.payload;
      })
      .addCase(itemStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchItemDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchItemDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.item = action.payload;
      })
      .addCase(fetchItemDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.item = action.payload;
      })
      .addCase(editItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addManufacturer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addManufacturer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.manufacturer = action.payload;
      })
      .addCase(addManufacturer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchManufacturerList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchManufacturerList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.manufacturer = action.payload;
      })
      .addCase(fetchManufacturerList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(manufacturerStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(manufacturerStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.manufacturer = action.payload;
      })
      .addCase(manufacturerStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchManufacturerDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchManufacturerDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.manufacturer = action.payload;
      })
      .addCase(fetchManufacturerDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchManufacturer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchManufacturer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.manufacturer = action.payload;
      })
      .addCase(fetchManufacturer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchAllItemList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllItemList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = action.payload;
      })
      .addCase(fetchAllItemList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchItemStateList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchItemStateList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.states = action.payload;
      })
      .addCase(fetchItemStateList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchItemPriceDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchItemPriceDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.price = action.payload;
      })
      .addCase(fetchItemPriceDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateItemPrice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateItemPrice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.price = action.payload;
      })
      .addCase(updateItemPrice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addItemGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addItemGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.item = action.payload;
      })
      .addCase(addItemGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchItemGroupList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchItemGroupList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = action.payload;
      })
      .addCase(fetchItemGroupList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchGroupDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchGroupDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = action.payload;
      })
      .addCase(fetchGroupDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(itemExcelImport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(itemExcelImport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.import = action.payload;
      })
      .addCase(itemExcelImport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(itemPriceMasterExcelExport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(itemPriceMasterExcelExport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.import = action.payload;
      })
      .addCase(itemPriceMasterExcelExport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteGroupItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteGroupItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.groupItem = action.payload.data;
      })
      .addCase(deleteGroupItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(depsItemList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(depsItemList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = action.payload.data;
      })
      .addCase(depsItemList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = itemSlice.actions;
export default itemSlice.reducer;
