import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Chip, ClickAwayListener, Paper, Popper, Toolbar } from '@mui/material';

// project import
import Search from './Search';
import Profile from './Profile';
// import Localization from './Localization';
import MegaMenuSection from '../../../CommonLayout/HeaderContent/MegaMenuSection';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';

// assets
import { MoreOutlined } from '@ant-design/icons';

// ==============================|| HEADER CONTENT - MOBILE ||============================== //

const MobileSection = () => {
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const selectedProduct = pathArray[1];
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  let filteredData;
  const user = JSON.parse(localStorage.getItem('user'));
  if (selectedProduct === 'oms' && user.organizationType === 2) {
    filteredData = JSON.parse(localStorage.getItem('omsFilteredData'));
  } else if ((selectedProduct === 'wms' || selectedProduct === 'tms') && user.organizationType === 2) {
    filteredData = JSON.parse(localStorage.getItem('wmsFilteredData'));
  } else if (user.organizationType === 3 && user.organizationSubType === 3) {
    filteredData = JSON.parse(localStorage.getItem('clientFilteredData'));
  } else if (user.organizationType === 3 && user.organizationSubType === 4) {
    filteredData = JSON.parse(localStorage.getItem('whPartnerFilteredData'));
  }

  const regex = /Odwen (\d+) /;

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <IconButton
          sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          color="secondary"
          variant="light"
        >
          <MoreOutlined />
        </IconButton>
      </Box>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          width: '100%'
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <AppBar color="inherit">
                  {user.organizationType === 2 && selectedProduct === 'oms' && filteredData && filteredData.client !== null && (
                    <Toolbar sx={{ p: 1 }}>
                      {filteredData && filteredData.client && (
                        <Chip
                          size="small"
                          color="primary"
                          label={`Customer: ${filteredData.client.organizationName}`}
                          sx={{ marginRight: 1 }}
                        />
                      )}
                      {filteredData && filteredData.warehouse.length > 0 && (
                        <Chip
                          size="small"
                          color="primary"
                          label={`Warehouse: ${
                            filteredData.warehouse.length > 1
                              ? `Multiple (${filteredData.warehouse.length})`
                              : filteredData &&
                                filteredData.warehouse.length > 0 &&
                                filteredData.warehouse[0].warehouseDisplayName.match(regex) &&
                                filteredData.warehouse[0].warehouseDisplayName.replace(regex, '')
                          }`}
                        />
                      )}
                    </Toolbar>
                  )}
                  {user.organizationType === 2 &&
                    (selectedProduct === 'wms' || selectedProduct === 'tms') &&
                    filteredData &&
                    filteredData.warehouse !== null && (
                      <Toolbar sx={{ p: 1 }}>
                        {filteredData && filteredData.client && (
                          <Chip
                            size="small"
                            color="primary"
                            label={`Customer: ${filteredData.client.clientName}`}
                            sx={{ marginRight: 1 }}
                          />
                        )}
                        {filteredData && filteredData.warehouse && (
                          <Chip
                            size="small"
                            color="primary"
                            label={`Warehouse: ${
                              filteredData &&
                              filteredData.warehouse &&
                              filteredData.warehouse.warehouseName.match(regex) &&
                              filteredData.warehouse.warehouseName.replace(regex, '')
                            }`}
                          />
                        )}
                      </Toolbar>
                    )}
                  {user.organizationType === 3 && user.organizationSubType === 3 && filteredData && filteredData.client !== null && (
                    <Toolbar sx={{ p: 1 }}>
                      {filteredData && filteredData.warehouse.length > 0 && (
                        <Chip
                          size="small"
                          color="primary"
                          label={`Warehouse: ${
                            filteredData.warehouse.length > 1
                              ? `Multiple (${filteredData.warehouse.length})`
                              : filteredData &&
                                filteredData.warehouse.length > 0 &&
                                filteredData.warehouse[0].warehouseDisplayName.match(regex) &&
                                filteredData.warehouse[0].warehouseDisplayName.replace(regex, '')
                          }`}
                        />
                      )}
                    </Toolbar>
                  )}
                  {user.organizationType === 3 && user.organizationSubType === 4 && filteredData && filteredData.warehouse !== null && (
                    <Toolbar sx={{ p: 1 }}>
                      {filteredData && filteredData.client && (
                        <Chip size="small" color="primary" label={`Customer: ${filteredData.client.clientName}`} sx={{ marginRight: 1 }} />
                      )}
                      {filteredData && filteredData.warehouse && (
                        <Chip
                          size="small"
                          color="primary"
                          label={`Warehouse: ${
                            filteredData &&
                            filteredData.warehouse &&
                            filteredData.warehouse.warehouseDisplayName.match(regex) &&
                            filteredData.warehouse.warehouseDisplayName.replace(regex, '')
                          }`}
                        />
                      )}
                    </Toolbar>
                  )}
                  <Toolbar>
                    <Search />
                    {/* <Localization /> */}
                    <MegaMenuSection />
                    <Profile />
                  </Toolbar>
                </AppBar>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default MobileSection;
