import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteAccessCheck from 'utils/route-guard/RouteAccessCheck';

// render - Inward Routes
const CreatePurchaseOrder = Loadable(lazy(() => import('pages/inward/createpurchaseorder')));
const PoDetails = Loadable(lazy(() => import('sections/inward/PoDetails')));
const CreateInvoice = Loadable(lazy(() => import('pages/inward/createpoinvoice')));
const FastProcessReceiving = Loadable(lazy(() => import('sections/inward/lot/FastProcessReceiving')));
const InwardReceive = Loadable(lazy(() => import('sections/inward/step-wise/InwardReceive')));
const InwardQc = Loadable(lazy(() => import('sections/inward/step-wise/InwardQc')));
const InwardPutaway = Loadable(lazy(() => import('sections/inward/step-wise/InwardPutaway')));
const PreGrn = Loadable(lazy(() => import('sections/inward/step-wise/PreGrn')));
const InvoiceDetails = Loadable(lazy(() => import('pages/inward/invoicedetails')));
const AllInward = Loadable(lazy(() => import('pages/inward/allinward')));
const AllInwardWMS = Loadable(lazy(() => import('sections/inward/AllInwardWmsList')));
const WmsReceiveList = Loadable(lazy(() => import('sections/inward/WmsReceiveList')));
const WmsQcList = Loadable(lazy(() => import('sections/inward/WmsQcList')));
const WmsPutawayList = Loadable(lazy(() => import('sections/inward/WmsPutawayList')));
const WmsPreGrnList = Loadable(lazy(() => import('sections/inward/WmsPreGrnList')));
const WmsGrnList = Loadable(lazy(() => import('sections/inward/WmsGrnList')));
const DraftList = Loadable(lazy(() => import('sections/inward/DraftList')));
const InwardCustomerReturn = Loadable(lazy(() => import('sections/inward/InwardCustomerReturn')));

// render - Outward Routes
const CreateSalesOrder = Loadable(lazy(() => import('pages/outward/createsalesorder')));
const SoDetails = Loadable(lazy(() => import('sections/outward/SoDetails')));
const StockTransfer = Loadable(lazy(() => import('pages/stock-transfer/createStockTransfer')));
const CustomerReturn = Loadable(lazy(() => import('sections/outward/CustomerReturn')));
const InStnOrderDetail = Loadable(lazy(() => import('pages/inward/stnDetail')));
const OutStnOrderDetail = Loadable(lazy(() => import('pages/outward/stnDetail')));

const OutWardList = Loadable(lazy(() => import('sections/outward/OutwardLists')));
const OutwardWMS = Loadable(lazy(() => import('sections/outward/OutwardWMS')));
const OutwardWMSAllOrder = Loadable(lazy(() => import('sections/outward/OutwardWMSAllOrder')));
const OutWardWMSPicklist = Loadable(lazy(() => import('sections/outward/OutwardPicklist')));
const OutwardPicklistGenerate = Loadable(lazy(() => import('sections/outward/OutwardPicklistGenerate')));

const OutwardPicklistOpen = Loadable(lazy(() => import('sections/outward/OutwardPicklistOpen')));
const OutwardPicklistClosed = Loadable(lazy(() => import('sections/outward/OutwardPicklistClosed')));
const OutwardPacking = Loadable(lazy(() => import('sections/outward/OutwardPacking')));
const OutwardStaging = Loadable(lazy(() => import('sections/outward/OutwardStageing')));
const OutwardPackageOpen = Loadable(lazy(() => import('sections/outward/OutwardPackageOpen')));
const OutwardPackageClosed = Loadable(lazy(() => import('sections/outward/OutwardPackageClosed')));
const OutwardWmsDispatch = Loadable(lazy(() => import('sections/outward/OutwardWmsDispatch')));
const OutwardDispatchOpen = Loadable(lazy(() => import('sections/outward/OutwardDispatchOpen')));
const OutwardDispatchClosed = Loadable(lazy(() => import('sections/outward/OutwardDispatchClosed')));
const OutwardDispatchHandover = Loadable(lazy(() => import('sections/outward/OutwardDispatchHandover')));
const OutwardWMSOrderDetail = Loadable(lazy(() => import('sections/outward/OutwardWMSOrderDetail')));

const ReportSetup = Loadable(lazy(() => import('pages/reports/reportSetup')));
const ReportList = Loadable(lazy(() => import('sections/report/reportList')));
const CreateReportSetting = Loadable(lazy(() => import('sections/report/createReportSetting')));
const ReportSetting = Loadable(lazy(() => import('sections/report/reportSetting')));

// UOM Master

const UomList = Loadable(lazy(() => import('pages/super-admin/master/uom/UomList')));
const PincodeList = Loadable(lazy(() => import('pages/super-admin/master/pincode/PincodeList')));
const QCList = Loadable(lazy(() => import('sections/admin/masters/client/registration/wms-setup/qc/QCList')));
const QCAdd = Loadable(lazy(() => import('sections/admin/masters/client/registration/wms-setup/qc/QCAdd')));
const BarcodeOrItemLayouts = Loadable(lazy(() => import('sections/admin/masters/client/registration/wms-setup/qc/BarcodeOrItemLayouts')));

const InventoryManagement = Loadable(lazy(() => import('sections/inventory_management/InventoryManagement')));
const ItemWiseInventory = Loadable(lazy(() => import('sections/inventory_management/ItemWiseInventory')));

// render - Deps Routes
const DepsOrderList = Loadable(lazy(() => import('pages/deps/depsList')));
const DepsViewOrder = Loadable(lazy(() => import('sections/deps/depsViewOrder')));
const ConvertDamage = Loadable(lazy(() => import('sections/deps/ConvertDamage')));
const ConvertFaulty = Loadable(lazy(() => import('sections/deps/ConvertFaulty')));
const ConvertGood = Loadable(lazy(() => import('sections/deps/ConvertGood')));
const SendForRepair = Loadable(lazy(() => import('sections/deps/SendForRepair')));
const DisposeOff = Loadable(lazy(() => import('sections/deps/DisposeOff')));

// ==============================|| MAIN ROUTING ||============================== //

const TransactionRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'oms',
          children: [
            {
              path: 'inward',
              children: [
                {
                  children: [
                    {
                      path: 'inward-list',
                      element: (
                        <RouteAccessCheck permissionName={'Inward List (OMS)'}>
                          <AllInward />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'invoice-details',
                      element: (
                        <RouteAccessCheck permissionName={'Invoice View'}>
                          <InvoiceDetails />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'create-purchase-order',
                      element: (
                        <RouteAccessCheck permissionName={'Inward Create'}>
                          <CreatePurchaseOrder />
                        </RouteAccessCheck>
                      ),
                      children: [
                        {
                          path: 'po-details',
                          element: (
                            <RouteAccessCheck permissionName={'Inward Create'}>
                              <PoDetails />
                            </RouteAccessCheck>
                          )
                        }
                      ]
                    },
                    {
                      path: 'edit-purchase-order',
                      element: (
                        <RouteAccessCheck permissionName={'Inward View'}>
                          <CreatePurchaseOrder />
                        </RouteAccessCheck>
                      ),
                      children: [
                        {
                          path: 'po-details',
                          element: (
                            <RouteAccessCheck permissionName={'Inward View'}>
                              <PoDetails />
                            </RouteAccessCheck>
                          )
                        }
                      ]
                    },
                    {
                      path: 'draft-purchase-order',
                      element: (
                        <RouteAccessCheck permissionName={'Draft View'}>
                          <CreatePurchaseOrder />
                        </RouteAccessCheck>
                      ),
                      children: [
                        {
                          path: 'po-details',
                          element: (
                            <RouteAccessCheck permissionName={'Draft View'}>
                              <PoDetails />
                            </RouteAccessCheck>
                          )
                        }
                      ]
                    },
                    {
                      path: 'create-invoice',
                      element: (
                        <RouteAccessCheck permissionName={'Invoice Create'}>
                          <CreateInvoice />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'edit-invoice',
                      element: (
                        <RouteAccessCheck permissionName={'Invoice View'}>
                          <CreateInvoice />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'customer-return',
                      element: (
                        <RouteAccessCheck permissionName={'Inward Create'}>
                          <InwardCustomerReturn />
                        </RouteAccessCheck>
                      )
                    }
                  ]
                }
              ]
            },
            {
              path: 'outward',
              children: [
                {
                  path: 'create-sales-order',
                  element: <CreateSalesOrder />,
                  children: [
                    {
                      path: 'so-details',
                      element: <SoDetails />
                    }
                  ]
                },
                {
                  path: 'outward-list',
                  element: <OutWardList />
                },
                { path: 'customer-return', element: <CustomerReturn /> }
              ]
            },
            {
              path: 'stock-transfer',
              children: [
                {
                  path: 'create-stock-transfer',
                  element: (
                    <RouteAccessCheck permissionName={'Create Stock Transfer'}>
                      <StockTransfer />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'draft-stock-transfer',
                  element: (
                    <RouteAccessCheck permissionName={'Draft View'}>
                      <StockTransfer />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'in-order-detail',
                  element: (
                    <RouteAccessCheck permissionName={'Inward View'}>
                      <InStnOrderDetail />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'out-order-detail',
                  element: (
                    <RouteAccessCheck permissionName={'Outward View'}>
                      <OutStnOrderDetail />
                    </RouteAccessCheck>
                  )
                }
              ]
            },
            {
              path: 'draft',
              children: [
                {
                  children: [
                    {
                      path: 'draft-list',
                      element: (
                        <RouteAccessCheck permissionName={'Draft List'}>
                          <DraftList />
                        </RouteAccessCheck>
                      )
                    }
                  ]
                }
              ]
            },
            {
              path: 'deps',
              children: [
                {
                  path: 'create-deps-order/convert-damage',
                  element: (
                    <RouteAccessCheck permissionName={'Convert Damage'}>
                      <ConvertDamage />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'create-deps-order/convert-faulty',
                  element: (
                    <RouteAccessCheck permissionName={'Convert Faulty'}>
                      <ConvertFaulty />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'create-deps-order/convert-good',
                  element: (
                    <RouteAccessCheck permissionName={'Convert Good'}>
                      <ConvertGood />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'create-deps-order/send-for-repair',
                  element: (
                    <RouteAccessCheck permissionName={'Out For Repair'}>
                      <SendForRepair />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'create-deps-order/dispose-off',
                  element: (
                    <RouteAccessCheck permissionName={'Dispose Off'}>
                      <DisposeOff />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'deps-order-list',
                  element: (
                    <RouteAccessCheck permissionName={'Deps List'}>
                      <DepsOrderList />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'view-order',
                  element: (
                    <RouteAccessCheck permissionName={'Deps View'}>
                      <DepsViewOrder />
                    </RouteAccessCheck>
                  )
                }
              ]
            },
            {
              path: 'report',
              children: [
                {
                  path: 'setup',
                  element: <ReportSetup />,
                  children: [
                    {
                      path: 'report-list',
                      element: <ReportList />
                    },
                    {
                      path: 'create-report-setting',
                      element: <CreateReportSetting />
                    },
                    {
                      path: 'setting',
                      element: <ReportSetting />
                    }
                  ]
                }
              ]
            },
            {
              path: 'uom',
              children: [
                {
                  path: 'list',
                  element: <UomList />
                }
              ]
            },
            {
              path: 'pincode',
              children: [
                {
                  path: 'list',
                  element: <PincodeList />
                }
              ]
            },
            {
              path: 'qc',
              children: [
                {
                  path: 'list',
                  element: <QCList />
                },
                {
                  path: 'add',
                  element: <QCAdd />
                }
              ]
            },
            {
              path: 'barcode/item',
              element: <BarcodeOrItemLayouts />
            }
          ]
        },
        {
          path: 'wms',
          children: [
            {
              path: 'inward',
              children: [
                {
                  children: [
                    {
                      path: 'inward-list',
                      element: (
                        <RouteAccessCheck permissionName={'Inward List (WMS)'}>
                          <AllInward />
                        </RouteAccessCheck>
                      ),
                      children: [
                        {
                          path: 'all',
                          element: (
                            <RouteAccessCheck permissionName={'Inward List (WMS)'}>
                              <AllInwardWMS />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'receive',
                          element: (
                            <RouteAccessCheck permissionName={'Inward List (WMS)'}>
                              <WmsReceiveList />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'qc',
                          element: (
                            <RouteAccessCheck permissionName={'Inward List (WMS)'}>
                              <WmsQcList />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'putaway',
                          element: (
                            <RouteAccessCheck permissionName={'Inward List (WMS)'}>
                              <WmsPutawayList />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'pre-grn',
                          element: (
                            <RouteAccessCheck permissionName={'Inward List (WMS)'}>
                              <WmsPreGrnList />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'grn',
                          element: (
                            <RouteAccessCheck permissionName={'Inward List (WMS)'}>
                              <WmsGrnList />
                            </RouteAccessCheck>
                          )
                        }
                      ]
                    },
                    {
                      path: 'verify-invoice',
                      element: (
                        <RouteAccessCheck permissionName={'Inward List (WMS)'}>
                          <CreateInvoice />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'grn-complete-from-excel',
                      element: (
                        <RouteAccessCheck permissionName={'Inward List (WMS)'}>
                          <FastProcessReceiving />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'invoice-details',
                      element: (
                        <RouteAccessCheck permissionName={'Inward List (OMS)'}>
                          <InvoiceDetails />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'receive',
                      element: <InwardReceive />
                    },
                    {
                      path: 'qc',
                      element: <InwardQc />
                    },
                    {
                      path: 'putaway',
                      element: <InwardPutaway />
                    },
                    {
                      path: 'pre-grn',
                      element: <PreGrn />
                    }
                  ]
                }
              ]
            },
            {
              path: 'outward',
              element: <OutwardWMS />,
              children: [
                {
                  children: [
                    {
                      path: 'allorders',
                      element: <OutwardWMSAllOrder />
                    },
                    {
                      path: 'picklist',
                      element: <OutWardWMSPicklist />,
                      children: [
                        {
                          path: 'generate',
                          element: <OutwardPicklistGenerate />
                        },
                        {
                          path: 'open',
                          element: <OutwardPicklistOpen />
                        },
                        {
                          path: 'closed',
                          element: <OutwardPicklistClosed />
                        }
                      ]
                    },
                    {
                      path: 'packing',
                      element: <OutwardPacking />,
                      children: [
                        {
                          path: 'open',
                          element: <OutwardPackageOpen />
                        },
                        {
                          path: 'closed',
                          element: <OutwardPackageClosed />
                        }
                      ]
                    },
                    {
                      path: 'staging',
                      element: <OutwardStaging />
                    },
                    {
                      path: 'dispatch',
                      element: <OutwardWmsDispatch />,
                      children: [
                        {
                          path: 'open',
                          element: <OutwardDispatchOpen />
                        },
                        {
                          path: 'closed',
                          element: <OutwardDispatchClosed />
                        },
                        {
                          path: 'handover',
                          element: <OutwardDispatchHandover />
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              path: 'stock-transfer',
              children: [
                {
                  path: 'out-order-detail',
                  element: <OutStnOrderDetail />
                }
              ]
            },
            { path: 'sodetails', element: <OutwardWMSOrderDetail /> },
            {
              path: 'inventory-management',
              element: <InventoryManagement />,
              children: [
                {
                  path: 'itemwise',
                  element: <ItemWiseInventory />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default TransactionRoutes;
