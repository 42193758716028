import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// ==============================|| Route Access Check ||============================== //

const RouteAccessCheck = ({ children, permissionName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const selectedProduct = pathArray[1];
  const user = JSON.parse(localStorage.getItem('user'));
  const permissionGiven = user?.permissionGiven;

  useEffect(() => {
    if (!user) {
      navigate('/login', { replace: true });
      return;
    }

    let grantedValue;
    for (let i = 0; i < permissionGiven.length; i++) {
      const obj = permissionGiven[i];
      const key = Object.keys(obj)[0];
      const permission = obj[key];

      if (permission.permissionName === permissionName) {
        grantedValue = permission.granted;
        break;
      }
    }
    if (grantedValue === false || grantedValue === undefined) {
      navigate(selectedProduct === 'oms' ? '/oms/maintenance/unauthorized' : '/wms/maintenance/unauthorized', { replace: true });
    }
  }, [navigate, permissionGiven, permissionName, selectedProduct, user]);

  return children;
};

RouteAccessCheck.propTypes = {
  children: PropTypes.node
};

export default RouteAccessCheck;
