import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

const addZone = async (zoneData) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/add_zone',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: zoneData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchZoneDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/zone_details',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateZone = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/update_zone',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchZoneList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `warehouse/list_zone?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const zoneStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/zone_status',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addPalletRack = async (palletRackData) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/add_pallet_rack',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: palletRackData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchPalletRackDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/pallet_rack_details',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updatePalletRack = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/update_pallet_rack',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchPalletRackList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `warehouse/list_pallet_rack?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const palletRackStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/pallet_rack_status',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addRow = async (rowData) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/add_warehouse_row',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: rowData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchRowDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/row_details',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateRow = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/update_row',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchRowList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `warehouse/warehouse_row_list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const rowStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/warehouse_row_status',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchBinFormData = async (organizationId) => {
  const config = {
    method: 'post',
    url: API_URL + `warehouse/add_bin_form_data`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addBinLocation = async (binLocationData) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/add_bin_location',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: binLocationData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchBinLocationList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `warehouse/bin_location_list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const binLocationStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/bin_location_status',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchBinLocationSerialization = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/bin_location_serialization',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const binSerializationUpdate = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/bin_serialization_update',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchAllAvailableBins = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `warehouse/all_binLocations`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const warehousesetupService = {
  addZone,
  fetchZoneDetail,
  updateZone,
  fetchZoneList,
  zoneStatus,
  addPalletRack,
  fetchPalletRackDetail,
  updatePalletRack,
  fetchPalletRackList,
  palletRackStatus,
  addRow,
  fetchRowDetail,
  updateRow,
  fetchRowList,
  rowStatus,
  fetchBinFormData,
  addBinLocation,
  fetchBinLocationList,
  binLocationStatus,
  fetchBinLocationSerialization,
  binSerializationUpdate,
  fetchAllAvailableBins
};

export default warehousesetupService;
