import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteAccessCheck from 'utils/route-guard/RouteAccessCheck';

// render - Item Master
const ItemList = Loadable(lazy(() => import('pages/client/masters/item/itemlist')));
const AddItem = Loadable(lazy(() => import('pages/client/masters/item/additem')));
const ItemPrice = Loadable(lazy(() => import('pages/client/masters/item/item-price-master/itemprice')));
const ImportPriceMaster = Loadable(lazy(() => import('pages/client/masters/item/item-price-master/importpricemaster')));
const ItemGroupList = Loadable(lazy(() => import('pages/client/masters/item/itemgrouplist')));
const AddItemGroup = Loadable(lazy(() => import('pages/client/masters/item/additemgroup')));
const ImportItemGroup = Loadable(lazy(() => import('pages/client/masters/item/importitemgroup')));
const GroupPrice = Loadable(lazy(() => import('pages/client/masters/item/groupprice')));
const ImportGroupPriceMaster = Loadable(lazy(() => import('pages/client/masters/item/importgrouppricemaster')));
const ItemCategoryList = Loadable(lazy(() => import('pages/client/masters/item/itemcategory')));
const AllPriceMaster = Loadable(lazy(() => import('pages/client/masters/item/item-price-master/allPriceMaster')));
const ApplicableStates = Loadable(lazy(() => import('pages/client/masters/item/item-price-master/applicableStates')));
const ImportItemExcel = Loadable(lazy(() => import('pages/client/masters/item/ImportItemExcel')));

// render - Vendor Master
const VendorList = Loadable(lazy(() => import('pages/client/masters/vendor/vendorlist')));
const AddVendor = Loadable(lazy(() => import('pages/client/masters/vendor/addvendor')));
const VendorProfile = Loadable(lazy(() => import('sections/client/masters/vendor/VendorProfile')));
const VendorAddressList = Loadable(lazy(() => import('sections/client/masters/vendor/VendorAddressList')));
const VendorBillingAddress = Loadable(lazy(() => import('sections/client/masters/vendor/VendorBillingAddress')));
const VendorProductCatalogue = Loadable(lazy(() => import('sections/client/masters/vendor/VendorProductCatalogue')));
const VendorAccountList = Loadable(lazy(() => import('sections/client/masters/vendor/VendorAccountList')));
const VendorBankDetails = Loadable(lazy(() => import('sections/client/masters/vendor/VendorBankDetails')));
const VendorOnlineDetails = Loadable(lazy(() => import('sections/client/masters/vendor/VendorOnlineDetails')));
const VendorOrdersHistory = Loadable(lazy(() => import('sections/client/masters/vendor/VendorOrdersHistory')));
const VendorTermsCondition = Loadable(lazy(() => import('sections/client/masters/vendor/VendorTermsCondition')));
const VendorActivityLog = Loadable(lazy(() => import('sections/client/masters/vendor/VendorActivityLog')));
// render - Vendor Master
const CustomerList = Loadable(lazy(() => import('pages/client/masters/customer/customerlist')));
const AddCustomer = Loadable(lazy(() => import('pages/client/masters/customer/addcustomer')));
const CustomerProfile = Loadable(lazy(() => import('sections/client/masters/customer/CustomerProfile')));
const CustomerAddressList = Loadable(lazy(() => import('sections/client/masters/customer/CustomerAddressList')));
const CustomerBillingAddress = Loadable(lazy(() => import('sections/client/masters/customer/CustomerBillingAddress')));
const CustomerAccountList = Loadable(lazy(() => import('sections/client/masters/customer/CustomerAccountList')));
const CustomerBankDetails = Loadable(lazy(() => import('sections/client/masters/customer/CustomerBankDetails')));
const CustomerOnlineDetails = Loadable(lazy(() => import('sections/client/masters/customer/CustomerOnlineDetails')));
const CustomerOrdersHistory = Loadable(lazy(() => import('sections/client/masters/customer/CustomerOrdersHistory')));
const CustomerTermsCondition = Loadable(lazy(() => import('sections/client/masters/customer/CustomerTermsCondition')));
const CustomerActivityLog = Loadable(lazy(() => import('sections/client/masters/customer/CustomerActivityLog')));
// render - Transporter Master
const AddTransporter = Loadable(lazy(() => import('pages/client/masters/transport/addtransporter')));
const TransporterList = Loadable(lazy(() => import('pages/client/masters/transport/transporterlist')));
const TransporterRegister = Loadable(lazy(() => import('pages/client/masters/transport/transporterRegister')));
const TransporterActivitylog = Loadable(lazy(() => import('pages/client/masters/transport/transporterActivitylog')));
const VehicleTypeList = Loadable(lazy(() => import('pages/client/masters/transport/VehicleTypeList')));
const AddVehicleType = Loadable(lazy(() => import('pages/client/masters/transport/AddVehicleType')));

// render - Terms and Conditions Master
const TncList = Loadable(lazy(() => import('pages/client/masters/tnc/tncList')));
const AddTnc = Loadable(lazy(() => import('pages/client/masters/tnc/addTnc')));

// render - External Warehouse Master
const ExternalWarehouse = Loadable(lazy(() => import('pages/client/masters/externalWarehouse/externalWarehouse')));
// ==============================|| MAIN ROUTING ||============================== //

const ClientRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'oms',
          children: [
            {
              path: 'master',
              children: [
                {
                  path: 'item',
                  children: [
                    {
                      path: 'list-item',
                      element: (
                        <RouteAccessCheck permissionName={'Item List'}>
                          <ItemList />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'add-item',
                      element: (
                        <RouteAccessCheck permissionName={'Item Create'}>
                          <AddItem />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'edit-item',
                      element: (
                        <RouteAccessCheck permissionName={'Item View'}>
                          <AddItem />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'all-price-master',
                      element: <AllPriceMaster />,
                      children: [
                        {
                          path: 'item-price',
                          element: <ItemPrice />
                        },
                        {
                          path: 'item-applicable-states',
                          element: <ApplicableStates />
                        }
                      ]
                    },
                    {
                      path: 'import-price-Master',
                      element: <ImportPriceMaster />
                    },
                    {
                      path: 'item-group-list',
                      element: (
                        <RouteAccessCheck permissionName={'Group Item List'}>
                          <ItemGroupList />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'import-item-group',
                      element: <ImportItemGroup />
                    },
                    {
                      path: 'add-item-group',
                      element: <AddItemGroup />
                    },
                    {
                      path: 'group-price-master',
                      element: <GroupPrice />
                    },
                    {
                      path: 'import-group-price-Master',
                      element: <ImportGroupPriceMaster />
                    },
                    {
                      path: 'list-category',
                      element: <ItemCategoryList />
                    },
                    {
                      path: 'import-items',
                      element: <ImportItemExcel />
                    }
                  ]
                },
                {
                  path: 'customer',
                  children: [
                    {
                      path: 'list-customer',
                      element: (
                        <RouteAccessCheck permissionName={'Customer List'}>
                          <CustomerList />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'edit-customer',
                      element: (
                        <RouteAccessCheck permissionName={'Customer View'}>
                          <AddCustomer />
                        </RouteAccessCheck>
                      ),
                      children: [
                        {
                          path: 'profile-customer',
                          element: (
                            <RouteAccessCheck permissionName={'Customer View'}>
                              <CustomerProfile />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'customer-address-list',
                          element: (
                            <RouteAccessCheck permissionName={'Customer View'}>
                              <CustomerAddressList />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'customer-billing-address',
                          element: (
                            <RouteAccessCheck permissionName={'Customer View'}>
                              <CustomerBillingAddress />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'customer-account-list',
                          element: (
                            <RouteAccessCheck permissionName={'Customer View'}>
                              <CustomerAccountList />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'customer-bank-details',
                          element: (
                            <RouteAccessCheck permissionName={'Customer View'}>
                              <CustomerBankDetails />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'customer-online-details',
                          element: (
                            <RouteAccessCheck permissionName={'Customer View'}>
                              <CustomerOnlineDetails />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'customer-orders-history',
                          element: (
                            <RouteAccessCheck permissionName={'Customer View'}>
                              <CustomerOrdersHistory />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'customer-terms-conditions',
                          element: (
                            <RouteAccessCheck permissionName={'Customer View'}>
                              <CustomerTermsCondition />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'customer-activity-log',
                          element: (
                            <RouteAccessCheck permissionName={'Customer View'}>
                              <CustomerActivityLog />
                            </RouteAccessCheck>
                          )
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'vendor',
                  children: [
                    {
                      path: 'list-vendor',
                      element: (
                        <RouteAccessCheck permissionName={'Vendor List'}>
                          <VendorList />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'edit-vendor',
                      element: (
                        <RouteAccessCheck permissionName={'Vendor View'}>
                          <AddVendor />
                        </RouteAccessCheck>
                      ),
                      children: [
                        {
                          path: 'profile-vendor',
                          element: (
                            <RouteAccessCheck permissionName={'Vendor View'}>
                              <VendorProfile />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'vendor-address-list',
                          element: (
                            <RouteAccessCheck permissionName={'Vendor View'}>
                              <VendorAddressList />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'vendor-billing-address',
                          element: (
                            <RouteAccessCheck permissionName={'Vendor View'}>
                              <VendorBillingAddress />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'vendor-product-catalogue',
                          element: (
                            <RouteAccessCheck permissionName={'Vendor View'}>
                              <VendorProductCatalogue />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'vendor-account-list',
                          element: (
                            <RouteAccessCheck permissionName={'Vendor View'}>
                              <VendorAccountList />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'vendor-bank-details',
                          element: (
                            <RouteAccessCheck permissionName={'Vendor View'}>
                              <VendorBankDetails />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'vendor-online-details',
                          element: (
                            <RouteAccessCheck permissionName={'Vendor View'}>
                              <VendorOnlineDetails />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'vendor-orders-history',
                          element: (
                            <RouteAccessCheck permissionName={'Vendor View'}>
                              <VendorOrdersHistory />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'vendor-terms-conditions',
                          element: (
                            <RouteAccessCheck permissionName={'Vendor View'}>
                              <VendorTermsCondition />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'vendor-activity-log',
                          element: (
                            <RouteAccessCheck permissionName={'Vendor View'}>
                              <VendorActivityLog />
                            </RouteAccessCheck>
                          )
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'transport',
                  children: [
                    {
                      path: 'list-transporter',
                      element: (
                        <RouteAccessCheck permissionName={'Transporter List'}>
                          <TransporterList />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'add-transporter',
                      element: (
                        <RouteAccessCheck permissionName={'Transporter Create'}>
                          <AddTransporter />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'transporter-register',
                      element: <TransporterRegister />,
                      children: [
                        {
                          path: 'edit-transporter',
                          element: (
                            <RouteAccessCheck permissionName={'Transporter View'}>
                              <AddTransporter />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'transporter-activity-log',
                          element: (
                            <RouteAccessCheck permissionName={'Transporter View'}>
                              <TransporterActivitylog />
                            </RouteAccessCheck>
                          )
                        }
                      ]
                    },
                    {
                      path: 'list-vehicle-type',
                      element: <VehicleTypeList />
                    },
                    {
                      path: 'add-vehicle-type',
                      element: <AddVehicleType />
                    }
                  ]
                },
                {
                  path: 'external-warehouse',
                  children: [
                    {
                      path: 'list-external-warehouse',
                      element: (
                        <RouteAccessCheck permissionName={'External Warehouse List'}>
                          <ExternalWarehouse />
                        </RouteAccessCheck>
                      )
                    }
                  ]
                },
                {
                  path: 'terms-conditions',
                  children: [
                    {
                      path: 'add-terms-conditions',
                      element: (
                        <RouteAccessCheck permissionName={'Tnc Create'}>
                          <AddTnc />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'edit-terms-conditions',
                      element: (
                        <RouteAccessCheck permissionName={'Tnc View'}>
                          <AddTnc />
                        </RouteAccessCheck>
                      )
                    },
                    {
                      path: 'list-terms-conditions',
                      element: (
                        <RouteAccessCheck permissionName={'Tnc List'}>
                          <TncList />
                        </RouteAccessCheck>
                      )
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default ClientRoutes;
