import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import customerService from './customerService';

const initialState = {
  customer: null,
  customers: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: '',
  address: null,
  masterAddresses: null
};

// Add Customer
export const addCustomer = createAsyncThunk('customer/add', async (customerData, thunkAPI) => {
  try {
    return await customerService.addCustomer(customerData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Customer List
export const fetchCustomerList = createAsyncThunk('customer/list', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await customerService.fetchCustomerList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Customer Status Toggle
export const customerStatus = createAsyncThunk('customer/status', async (data, thunkAPI) => {
  try {
    return await customerService.customerStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Single Customer Detail
export const fetchCustomerDetail = createAsyncThunk('customer/get_single', async (data, thunkAPI) => {
  try {
    return await customerService.fetchCustomerDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Customer Updation
export const updateCustomer = createAsyncThunk('customer/edit', async (data, thunkAPI) => {
  try {
    return await customerService.updateCustomer(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getAllCustomer = createAsyncThunk('customer/get_all', async (organizationId, thunkAPI) => {
  try {
    return await customerService.getAllCustomer(organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchCustomerAddressList = createAsyncThunk('customer/customer_address_list', async (customerId, thunkAPI) => {
  try {
    return await customerService.fetchCustomerAddressList(customerId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Customer Master Address List
export const fetchCustomerAddressMasterList = createAsyncThunk('customer/list_address', async ({ pageNum, customerId }, thunkAPI) => {
  try {
    return await customerService.fetchCustomerAddressMasterList(pageNum, customerId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Customer Address Detail
export const fetchCustomerAddressDetail = createAsyncThunk('customer/address_detail', async (data, thunkAPI) => {
  try {
    return await customerService.fetchCustomerAddressDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add CUstomer Address
export const addCustomerAddress = createAsyncThunk('customer/add_address', async (customerAddressData, thunkAPI) => {
  try {
    return await customerService.addCustomerAddress(customerAddressData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Customer Address Updation
export const updateCustomerAddress = createAsyncThunk('customer/update_address', async (data, thunkAPI) => {
  try {
    return await customerService.updateCustomerAddress(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customer = action.payload;
      })
      .addCase(addCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchCustomerList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomerList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customers = action.payload;
      })
      .addCase(fetchCustomerList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(customerStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(customerStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customer = action.payload;
      })
      .addCase(customerStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchCustomerDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomerDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customer = action.payload;
      })
      .addCase(fetchCustomerDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customer = action.payload;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customers = action.payload;
      })
      .addCase(getAllCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchCustomerAddressList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomerAddressList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.address = action.payload;
      })
      .addCase(fetchCustomerAddressList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchCustomerAddressMasterList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomerAddressMasterList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.masterAddresses = action.payload;
      })
      .addCase(fetchCustomerAddressMasterList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchCustomerAddressDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomerAddressDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.address = action.payload;
      })
      .addCase(fetchCustomerAddressDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addCustomerAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCustomerAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.masterAddresses = action.payload;
      })
      .addCase(addCustomerAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateCustomerAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCustomerAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.masterAddresses = action.payload;
      })
      .addCase(updateCustomerAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = customerSlice.actions;
export default customerSlice.reducer;
