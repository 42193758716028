import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteAccessCheck from 'utils/route-guard/RouteAccessCheck';

// render - General Routes
const DefaultFormSchemaList = Loadable(lazy(() => import('pages/admin/setup/formcustomization/DefaultFormSchemaList')));
const DefaultFormSchema = Loadable(lazy(() => import('pages/admin/setup/formcustomization/DefaultFormSchema')));
const DefaultFormCreation = Loadable(lazy(() => import('pages/admin/setup/formcustomization/DefaultFormCreation')));
// render - Organization Master
const OrganizationList = Loadable(lazy(() => import('pages/admin/masters/organization/OrganizationList')));
const OrganizationRegistration = Loadable(lazy(() => import('pages/admin/masters/organization/OrganizationRegistration')));
// ============================== Organization profile tabs ============================= //
const OrganizationProfile = Loadable(lazy(() => import('pages/admin/masters/organization/tabs/OrganizationProfile')));
const OrganizationBasic = Loadable(lazy(() => import('sections/admin/masters/organization/OrganizationProfileBasic')));
const OrganizationAddress = Loadable(lazy(() => import('sections/admin/masters/organization/OrganizationProfileAddress')));
const OrganizationContact = Loadable(lazy(() => import('sections/admin/masters/organization/OrganizationProfileContact')));
const OrganizationDocuments = Loadable(lazy(() => import('sections/admin/masters/organization/OrganizationProfileDocuments')));
const OrganizationBank = Loadable(lazy(() => import('sections/admin/masters/organization/OrganizationProfileBank')));
// ============================= Organization wmsplan tabs =================================== //
const OrganizationWmsPlan = Loadable(lazy(() => import('pages/admin/masters/organization/tabs/OrganizationWmsPlan')));
// roles and Permission
const Permission = Loadable(lazy(() => import('pages/roles_permission/Permission')));
const PermissionList = Loadable(lazy(() => import('pages/roles_permission/PermissionList')));
const RolesPermission = Loadable(lazy(() => import('pages/roles_permission/RolesPermission')));
const AddNewRole = Loadable(lazy(() => import('sections/roles_permission/role/AddNewRole')));
const EditEmployee = Loadable(lazy(() => import('sections/roles_permission/employee/EditEmployee')));
// ==============================|| MAIN ROUTING ||============================== //

const GeneralRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'oms',
          children: [
            {
              path: 'general',
              children: [
                {
                  children: [
                    {
                      path: 'default-form-schema-list',
                      element: <DefaultFormSchemaList />
                    },
                    {
                      path: 'default-form-schema/:formId',
                      element: <DefaultFormSchema />
                    },
                    {
                      path: 'default-form-creation',
                      element: <DefaultFormCreation />
                    }
                  ]
                },
                {
                  children: [
                    {
                      path: 'organization',
                      children: [
                        {
                          path: 'organization-list',
                          element: (
                            <RouteAccessCheck permissionName={'Organization List'}>
                              <OrganizationList />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'registration',
                          element: (
                            <RouteAccessCheck permissionName={'Organization View'}>
                              <OrganizationRegistration />
                            </RouteAccessCheck>
                          ),
                          children: [
                            {
                              path: 'profile',
                              element: (
                                <RouteAccessCheck permissionName={'Organization View'}>
                                  <OrganizationProfile />
                                </RouteAccessCheck>
                              ),
                              children: [
                                {
                                  path: 'basic',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationBasic />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'address',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationAddress />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'contact',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationContact />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'documents',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationDocuments />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'bank',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationBank />
                                    </RouteAccessCheck>
                                  )
                                }
                              ]
                            },
                            {
                              path: 'wms-plan',
                              element: (
                                <RouteAccessCheck permissionName={'Organization View'}>
                                  <OrganizationWmsPlan />
                                </RouteAccessCheck>
                              )
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'permission',
                  element: (
                    <RouteAccessCheck permissionName={'Add Permission'}>
                      <Permission />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'list-permission',
                  element: (
                    <RouteAccessCheck permissionName={'Permission List'}>
                      <PermissionList />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'roles-permission',
                  element: (
                    <RouteAccessCheck permissionName={'User & Roles'}>
                      <RolesPermission />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'add-role',
                  element: (
                    <RouteAccessCheck permissionName={'Roles View'}>
                      <AddNewRole />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'edit-employee',
                  element: (
                    <RouteAccessCheck permissionName={'User View'}>
                      <EditEmployee />
                    </RouteAccessCheck>
                  )
                }
              ]
            }
          ]
        },
        {
          path: 'wms',
          children: [
            {
              path: 'general',
              children: [
                {
                  children: [
                    {
                      path: 'default-form-schema-list',
                      element: <DefaultFormSchemaList />
                    },
                    {
                      path: 'default-form-schema/:formId',
                      element: <DefaultFormSchema />
                    },
                    {
                      path: 'default-form-creation',
                      element: <DefaultFormCreation />
                    }
                  ]
                },
                {
                  children: [
                    {
                      path: 'organization',
                      children: [
                        {
                          path: 'organization-list',
                          element: (
                            <RouteAccessCheck permissionName={'Organization List'}>
                              <OrganizationList />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'registration',
                          element: (
                            <RouteAccessCheck permissionName={'Organization View'}>
                              <OrganizationRegistration />
                            </RouteAccessCheck>
                          ),
                          children: [
                            {
                              path: 'profile',
                              element: (
                                <RouteAccessCheck permissionName={'Organization View'}>
                                  <OrganizationProfile />
                                </RouteAccessCheck>
                              ),
                              children: [
                                {
                                  path: 'basic',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationBasic />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'address',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationAddress />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'contact',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationContact />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'documents',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationDocuments />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'bank',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationBank />
                                    </RouteAccessCheck>
                                  )
                                }
                              ]
                            },
                            {
                              path: 'wms-plan',
                              element: (
                                <RouteAccessCheck permissionName={'Organization View'}>
                                  <OrganizationWmsPlan />
                                </RouteAccessCheck>
                              )
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'permission',
                  element: (
                    <RouteAccessCheck permissionName={'Add Permission'}>
                      <Permission />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'list-permission',
                  element: (
                    <RouteAccessCheck permissionName={'Permission List'}>
                      <PermissionList />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'roles-permission',
                  element: (
                    <RouteAccessCheck permissionName={'User & Roles'}>
                      <RolesPermission />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'add-role',
                  element: (
                    <RouteAccessCheck permissionName={'Roles View'}>
                      <AddNewRole />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'edit-employee',
                  element: (
                    <RouteAccessCheck permissionName={'User View'}>
                      <EditEmployee />
                    </RouteAccessCheck>
                  )
                }
              ]
            }
          ]
        },
        {
          path: 'tms',
          children: [
            {
              path: 'general',
              children: [
                {
                  children: [
                    {
                      path: 'default-form-schema-list',
                      element: <DefaultFormSchemaList />
                    },
                    {
                      path: 'default-form-schema/:formId',
                      element: <DefaultFormSchema />
                    },
                    {
                      path: 'default-form-creation',
                      element: <DefaultFormCreation />
                    }
                  ]
                },
                {
                  children: [
                    {
                      path: 'organization',
                      children: [
                        {
                          path: 'organization-list',
                          element: (
                            <RouteAccessCheck permissionName={'Organization List'}>
                              <OrganizationList />
                            </RouteAccessCheck>
                          )
                        },
                        {
                          path: 'registration',
                          element: (
                            <RouteAccessCheck permissionName={'Organization View'}>
                              <OrganizationRegistration />
                            </RouteAccessCheck>
                          ),
                          children: [
                            {
                              path: 'profile',
                              element: (
                                <RouteAccessCheck permissionName={'Organization View'}>
                                  <OrganizationProfile />
                                </RouteAccessCheck>
                              ),
                              children: [
                                {
                                  path: 'basic',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationBasic />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'address',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationAddress />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'contact',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationContact />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'documents',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationDocuments />
                                    </RouteAccessCheck>
                                  )
                                },
                                {
                                  path: 'bank',
                                  element: (
                                    <RouteAccessCheck permissionName={'Organization View'}>
                                      <OrganizationBank />
                                    </RouteAccessCheck>
                                  )
                                }
                              ]
                            },
                            {
                              path: 'wms-plan',
                              element: (
                                <RouteAccessCheck permissionName={'Organization View'}>
                                  <OrganizationWmsPlan />
                                </RouteAccessCheck>
                              )
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  path: 'permission',
                  element: (
                    <RouteAccessCheck permissionName={'Add Permission'}>
                      <Permission />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'list-permission',
                  element: (
                    <RouteAccessCheck permissionName={'Permission List'}>
                      <PermissionList />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'roles-permission',
                  element: (
                    <RouteAccessCheck permissionName={'User & Roles'}>
                      <RolesPermission />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'add-role',
                  element: (
                    <RouteAccessCheck permissionName={'Roles View'}>
                      <AddNewRole />
                    </RouteAccessCheck>
                  )
                },
                {
                  path: 'edit-employee',
                  element: (
                    <RouteAccessCheck permissionName={'User View'}>
                      <EditEmployee />
                    </RouteAccessCheck>
                  )
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default GeneralRoutes;
