import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import organizationService from './organizationService';

const initialState = {
  organization: null,
  products: [],
  customerProducts: [],
  clientList: [],
  organizations: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: ''
};

// Fetch Organizations
export const fetchOrganizations = createAsyncThunk('organization/getall', async ({ pageNum, orgType, organizationSubType }, thunkAPI) => {
  try {
    return await organizationService.fetchOrganizations(pageNum, orgType, organizationSubType);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Organization Registeration
export const postData = createAsyncThunk('organization/add', async (organizationData, thunkAPI) => {
  try {
    return await organizationService.postData(organizationData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Organization Updation
export const updateOrganization = createAsyncThunk('organization/updatebasic', async (newOrganizationData, thunkAPI) => {
  try {
    return await organizationService.updateOrganization(newOrganizationData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Single Organization Detail
export const fetchOrganizationDetail = createAsyncThunk('organization/getsingleorganization', async (_id, thunkAPI) => {
  try {
    return await organizationService.fetchOrganizationDetail(_id);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Organization Add Address
export const organizationAddAddress = createAsyncThunk('organization/addressupdate', async (addressData, thunkAPI) => {
  try {
    return await organizationService.organizationAddAddress(addressData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Organization Authorization Update
export const organizationAuthorizationUpdate = createAsyncThunk('organization/authorizationupdate', async (authorizationData, thunkAPI) => {
  try {
    return await organizationService.organizationAuthorizationUpdate(authorizationData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Organization Documents Update
export const organizationDocumentsUpdate = createAsyncThunk('organization/updatedocument', async (documentsData, thunkAPI) => {
  try {
    return await organizationService.organizationDocumentsUpdate(documentsData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Organization Bank Details Update
export const organizationBankDetailsUpdate = createAsyncThunk('organization/bankdetailupdate', async (bankDetailsData, thunkAPI) => {
  try {
    return await organizationService.organizationBankDetailsUpdate(bankDetailsData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Organization Status Toggle
export const organizationStatus = createAsyncThunk('organization/status', async (data, thunkAPI) => {
  try {
    return await organizationService.organizationStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Organization Products
export const fetchOrganizationProducts = createAsyncThunk('organization/getallproducts', async (thunkAPI) => {
  try {
    return await organizationService.fetchOrganizationProducts();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Customer Products
export const fetchCustomerProducts = createAsyncThunk('organization/get_organization_product', async (thunkAPI) => {
  try {
    return await organizationService.fetchCustomerProducts();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchClientList = createAsyncThunk('organization/client_list', async (thunkAPI) => {
  try {
    return await organizationService.fetchClientList();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const orgSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organizations = action.payload;
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(postData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(postData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateOrganization.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(updateOrganization.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchOrganizationDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrganizationDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(fetchOrganizationDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(organizationAddAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(organizationAddAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(organizationAddAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(organizationAuthorizationUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(organizationAuthorizationUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(organizationAuthorizationUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(organizationDocumentsUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(organizationDocumentsUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(organizationDocumentsUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(organizationBankDetailsUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(organizationBankDetailsUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(organizationBankDetailsUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(organizationStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(organizationStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organization = action.payload;
      })
      .addCase(organizationStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchOrganizationProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrganizationProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.products = action.payload.data;
      })
      .addCase(fetchOrganizationProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchCustomerProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomerProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customerProducts = action.payload.data;
      })
      .addCase(fetchCustomerProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchClientList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClientList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clientList = action.payload.data;
      })
      .addCase(fetchClientList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = orgSlice.actions;
export default orgSlice.reducer;
