import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import formCustomizationService from './formCustomizationService';

const initialState = {
  formDetails: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: ''
};

// Add Default Form Schema
export const addDefaultFormSchema = createAsyncThunk('form/default_form_schema', async (defaultFormSchemaDetails, thunkAPI) => {
  try {
    return await formCustomizationService.addDefaultFormSchema(defaultFormSchemaDetails);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Update Default Form Schema
export const defaultFormSchemaUpdate = createAsyncThunk('form/update_default_form', async (defaultFormSchemaDetails, thunkAPI) => {
  try {
    return await formCustomizationService.defaultFormSchemaUpdate(defaultFormSchemaDetails);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Default Form Schema List
export const fetchDefaultFormSchema = createAsyncThunk('form/list_form_schema', async (pageNum, thunkAPI) => {
  try {
    return await formCustomizationService.fetchDefaultFormSchema(pageNum);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Single Default Form Schema
export const fetchSingleDefaultFormSchema = createAsyncThunk('form/get_form_schema', async (formId, thunkAPI) => {
  try {
    return await formCustomizationService.fetchSingleDefaultFormSchema(formId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Default Form Fields
export const fetchDefaultFormFields = createAsyncThunk('form/default_form_field', async (formId, thunkAPI) => {
  try {
    return await formCustomizationService.fetchDefaultFormFields(formId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add Default Form
export const addDefaultForm = createAsyncThunk('form/add_form_detail', async (data, thunkAPI) => {
  try {
    return await formCustomizationService.addDefaultForm(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Edit Default Form
export const editDefaultForm = createAsyncThunk('form/edit_form_detail', async (data, thunkAPI) => {
  try {
    return await formCustomizationService.editDefaultForm(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Default Form
export const fetchDefaultForm = createAsyncThunk('form/view_default_form', async (data, thunkAPI) => {
  try {
    return await formCustomizationService.fetchDefaultForm(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Multiple Form
export const fetchMultipleCustomizeForm = createAsyncThunk('form/view_multiple_form', async (data, thunkAPI) => {
  try {
    return await formCustomizationService.fetchMultipleCustomizeForm(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const formCustomizationSlice = createSlice({
  name: 'formCustomization',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addDefaultFormSchema.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addDefaultFormSchema.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formDetails = action.payload;
      })
      .addCase(addDefaultFormSchema.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(defaultFormSchemaUpdate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(defaultFormSchemaUpdate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formDetails = action.payload;
      })
      .addCase(defaultFormSchemaUpdate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchDefaultFormSchema.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDefaultFormSchema.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formDetails = action.payload;
      })
      .addCase(fetchDefaultFormSchema.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchSingleDefaultFormSchema.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleDefaultFormSchema.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formDetails = action.payload;
      })
      .addCase(fetchSingleDefaultFormSchema.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchDefaultFormFields.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDefaultFormFields.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formDetails = action.payload;
      })
      .addCase(fetchDefaultFormFields.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addDefaultForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addDefaultForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formDetails = action.payload;
      })
      .addCase(addDefaultForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editDefaultForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editDefaultForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formDetails = action.payload;
      })
      .addCase(editDefaultForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchDefaultForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDefaultForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formDetails = action.payload;
      })
      .addCase(fetchDefaultForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchMultipleCustomizeForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMultipleCustomizeForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formDetails = action.payload;
      })
      .addCase(fetchMultipleCustomizeForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = formCustomizationSlice.actions;
export default formCustomizationSlice.reducer;
