//LIBRARY IMPORT
import axios from 'axios';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import * as AntdIcons from '@ant-design/icons';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const API_URL = process.env.REACT_APP_LIVE_API_URL;
// const API_URL = process.env.REACT_APP_API_URL;
const TOKEN = localStorage.getItem('serviceToken');
const user = JSON.parse(localStorage.getItem('user'));
const product = window.location.pathname.split('/')[1].toUpperCase();
const data = {
  product: product,
  moduleId: 'transactions',
  organizationType: user ? user.organizationType : '',
  userType: user ? user.userType : ''
};

const transactions = {
  id: '',
  title: '',
  type: 'group',
  children: []
};

const fetchData = async () => {
  try {
    const config = {
      method: 'post',
      url: API_URL + 'common_setup/fetch_side_menu',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + TOKEN
      },
      data: data
    };
    const response = await axios(config);
    // if (Object.keys(response.data.data).length > 1) {
    const test_json = response.data.data;
    if (test_json && test_json != undefined) {
      transactions.id = test_json.id;
      transactions.title = <FormattedMessage id={test_json.title} defaultMessage={test_json.title} />;
      transactions.type = test_json.type;

      for (let i = 0; i < test_json.children.length; i++) {
        var first_level = {
          id: test_json.children[i].id,
          title: <FormattedMessage id={test_json.children[i].title} defaultMessage={test_json.children[i].title} />,
          type: test_json.children[i].type,
          icon: AntdIcons[test_json.children[i].icon],
          url: test_json.children[i].url,
          children: []
        };
        if (test_json.children[i].children != undefined) {
          for (let j = 0; j < test_json.children[i].children.length; j++) {
            var second_level = {
              id: test_json.children[i].children[j].id,
              title: (
                <FormattedMessage id={test_json.children[i].children[j].title} defaultMessage={test_json.children[i].children[j].title} />
              ),
              type: test_json.children[i].children[j].type,
              icon: AntdIcons[test_json.children[i].children[j].icon],
              url: test_json.children[i].children[j].url
            };
            first_level.children.push(second_level);
          }
        }
        transactions.children.push(first_level);
      }
    }
    // }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return;
    }
  }
};

if (user) {
  fetchData();
}
export default transactions;
