import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

const addCategory = async (categoryData) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/add_category',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: categoryData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchCategoryList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `item/list_category?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const categoryStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/update_category_status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchCategoryDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/category_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateCategory = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/edit_category',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchCategory = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/list_all_category',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addSubCategory = async (subCategoryData) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/add_sub_category',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: subCategoryData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchSubCategoryList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `item/list_sub_category?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const subCategoryStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/update_sub_category_status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchSubCategoryDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/sub_category_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateSubCategory = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/edit_sub_category',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addBrand = async (brandData) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/add_brand',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: brandData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchBrandList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `item/list_brand?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const brandStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/update_brand_status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchBrandDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/brand_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateBrand = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/edit_brand',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchBrand = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/list_all_brand',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchAllSubCategory = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/list_all_sub_category`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchAllUom = async () => {
  const config = {
    method: 'post',
    url: API_URL + `common_setup/all_uom`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addItem = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/add_item`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchItemsList = async (page, organizationId, organizationType) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `item/item_list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId,
      organizationType: organizationType
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const itemStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/update_item_status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchItemDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/item_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const editItem = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/edit_item`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addManufacturer = async (manufacturerData) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/add_manufacturer',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: manufacturerData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchManufacturerList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `item/list_manufacturer?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const manufacturerStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/update_manufacturer_status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchManufacturerDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/manufacturer_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateManufacturer = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/edit_manufacturer',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchManufacturer = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/list_all_manufacturer',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchAllItemList = async (page, limit, organizationId) => {
  let pageNum = Number(page);
  let limitNum = Number(limit);
  const config = {
    method: 'post',
    url: API_URL + `item/item_list_with_price?page=${pageNum}&limit=${limitNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchItemStateList = async () => {
  const config = {
    method: 'get',
    url: API_URL + '/item/applicable_state_list',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchItemPriceDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/single_item_price_details`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateItemPrice = async (itemPriceData) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/update_single_item_price',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: itemPriceData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Group Items
const addItemGroup = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/add_item_group`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchItemGroupList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `item/item_group_list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchGroupDetails = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/group_details`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const deleteGroupItem = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'item/delete_group_item',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const itemExcelImport = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/import`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const itemPriceMasterExcelExport = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/item_price_excel_export`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const depsItemList = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `item/deps_item_list`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const itemService = {
  addCategory,
  fetchCategoryList,
  categoryStatus,
  fetchCategoryDetail,
  updateCategory,
  fetchCategory,
  addSubCategory,
  fetchSubCategoryList,
  subCategoryStatus,
  fetchSubCategoryDetail,
  updateSubCategory,
  addBrand,
  fetchBrandList,
  brandStatus,
  fetchBrandDetail,
  updateBrand,
  fetchBrand,
  fetchAllSubCategory,
  fetchAllUom,
  addItem,
  fetchItemsList,
  itemStatus,
  fetchItemDetail,
  editItem,
  addManufacturer,
  fetchManufacturerList,
  manufacturerStatus,
  fetchManufacturerDetail,
  updateManufacturer,
  fetchManufacturer,
  fetchAllItemList,
  fetchItemStateList,
  fetchItemPriceDetail,
  updateItemPrice,
  addItemGroup,
  fetchItemGroupList,
  fetchGroupDetails,
  itemExcelImport,
  itemPriceMasterExcelExport,
  deleteGroupItem,
  depsItemList
};

export default itemService;
