import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

const fetchWarehouse = async (page) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `warehouse/list_warehouse?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const warehouseStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `warehouse/status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchWarehouseDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `warehouse/getsinglewarehouse`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addWarehouse = async (warehouseData) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/add',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: warehouseData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateWarehouse = async (newWarehouseData) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/edit',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: newWarehouseData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addSpaceBooking = async (spaceBookingData) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/add_booking_space',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: spaceBookingData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchSpaceBookingList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `warehouse/list_booking_space?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const spaceBookingStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `warehouse/booking_space_status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const singleSpaceBooking = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/booking_space_details',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateSpaceBooking = async (newSpaceBookingData) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/edit_booking_space',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: newSpaceBookingData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const listWarehousePartner = async () => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/all_warehouse_partner',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchExternalWarehouseList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `warehouse/external_wh_list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addExternalWarehouse = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/add_external_wh',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchExternalWarehouseDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `warehouse/get_external_wh_details`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const externalWarehouseStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `warehouse/external_wh_status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateExternalWarehouse = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/update_external_wh',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchStnExternalWarehouse = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `warehouse/stn_external_wh_list`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchMotherDarkWarehouseList = async (organizationId) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/mother_dark_warehouse_list',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addWarehouseRelation = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/add_warehouse_relation',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchWarehouseRelationList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `warehouse/list_warehouse_relation?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const deleteWarehouseRelation = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/delete_warehouse_relation',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchAllInventories = async (page, spaceBookingId, selectedSKU, selectedBIN) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `warehouse/all_inventories?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      spaceBookingId: spaceBookingId,
      selectedSKU: selectedSKU,
      selectedBIN: selectedBIN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addNewBinLocations = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/add_new_bin_locations',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchClientWarehouse = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `warehouse/client_warehouse_list`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const warehouseService = {
  fetchWarehouse,
  warehouseStatus,
  fetchWarehouseDetail,
  addWarehouse,
  updateWarehouse,
  addSpaceBooking,
  fetchSpaceBookingList,
  spaceBookingStatus,
  singleSpaceBooking,
  updateSpaceBooking,
  listWarehousePartner,
  fetchExternalWarehouseList,
  addExternalWarehouse,
  fetchExternalWarehouseDetail,
  externalWarehouseStatus,
  updateExternalWarehouse,
  fetchStnExternalWarehouse,
  fetchMotherDarkWarehouseList,
  addWarehouseRelation,
  fetchWarehouseRelationList,
  deleteWarehouseRelation,
  fetchAllInventories,
  addNewBinLocations,
  fetchClientWarehouse
};

export default warehouseService;
