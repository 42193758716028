import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
// const API_URL = process.env.REACT_APP_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

const addDefaultFormSchema = async (defaultFormSchemaDetails) => {
  const config = {
    method: 'post',
    url: API_URL + 'form/default_form_schema',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: defaultFormSchemaDetails
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchDefaultFormSchema = async (page) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `form/list_form_schema?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchSingleDefaultFormSchema = async (_id) => {
  const data = {
    id: _id
  };
  const config = {
    method: 'post',
    url: API_URL + `form/get_form_schema`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const defaultFormSchemaUpdate = async (defaultFormSchemaDetails) => {
  const config = {
    method: 'post',
    url: API_URL + 'form/update_default_form',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: defaultFormSchemaDetails
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchDefaultFormFields = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `form/default_form_field`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addDefaultForm = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `form/add_form_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const editDefaultForm = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `form/edit_form_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchDefaultForm = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `form/view_default_form`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchMultipleCustomizeForm = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `form/view_multiple_form`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const formCustomizationService = {
  addDefaultFormSchema,
  fetchDefaultFormSchema,
  fetchSingleDefaultFormSchema,
  defaultFormSchemaUpdate,
  fetchDefaultFormFields,
  addDefaultForm,
  editDefaultForm,
  fetchDefaultForm,
  fetchMultipleCustomizeForm
};

export default formCustomizationService;
