import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
// const API_URL = process.env.REACT_APP_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

const register = async (userData) => {
  const config = {
    method: 'post',
    url: API_URL + 'user/register',
    headers: {
      'Content-Type': 'application/json'
    },
    data: userData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const sendOtp = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'user/send_otp',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const validateOtp = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'user/validate_otp',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const resetPassword = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'user/reset_password',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Filter Services OMS
const fetchFilteredClient = async (userType) => {
  const data = {
    userType: userType
  };
  const config = {
    method: 'post',
    url: API_URL + 'organization/filter_client',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchFilteredWarehouse = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/filter_warehouse',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

// Filter Services WMS
const fetchWmsFilterWarehouse = async () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const data = {
    organizationType: user.organizationType,
    organizationSubType: user.organizationSubType,
    userType: user.userType
  };
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/filter_booking_space',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchWmsFilteredClient = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/filter_warehouse_client',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchWhPartnerFilteredWarehouse = async () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const data = {
    organizationType: user.organizationType,
    organizationSubType: user.organizationSubType,
    userType: user.userType
  };
  const config = {
    method: 'post',
    url: API_URL + 'warehouse/filter_whpartner_warehouse',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchDashboardDetails = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `common/dashboard_details`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchOrderOverview = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `common/order_overview`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const authenticationService = {
  register,
  sendOtp,
  validateOtp,
  resetPassword,
  fetchFilteredClient,
  fetchFilteredWarehouse,
  fetchWmsFilterWarehouse,
  fetchWmsFilteredClient,
  fetchWhPartnerFilteredWarehouse,
  fetchDashboardDetails,
  fetchOrderOverview
};

export default authenticationService;
