// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project import
import chat from './chat';
import calendar from './calendar';
import menu from './menu';
import snackbar from './snackbar';
import productReducer from './product';
import cartReducer from './cart';
import authenticationReducer from './features/authentication/authenticationSlice';
import organizationReducer from './features/organization/organizationSlice';
import formCustomizationReducer from './features/formcustomization/formCustomizationSlice';
import permissionReducer from './features/permissions/permissionSlice';
import warehouseReducer from './features/warehouse/warehouseSlice';
import itemReducer from './features/item/itemSlice';
import vendorReducer from './features/vendor/vendorSlice';
import depReducer from './features/deps/depSlice';
import customerReducer from './features/customer/customerSlice';
import clientReducer from './features/client/clientSlice';
import warehouseSetupReducer from './features/warehousesetup/warehousesetupSlice';
import inwardReducer from './features/inward/inwardSlice';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  chat,
  calendar,
  menu,
  snackbar,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'mantis-ts-'
    },
    cartReducer
  ),
  product: productReducer,
  authentication: authenticationReducer,
  organization: organizationReducer,
  formCustomization: formCustomizationReducer,
  permissions: permissionReducer,
  warehouse: warehouseReducer,
  item: itemReducer,
  vendor: vendorReducer,
  deps: depReducer,
  customer: customerReducer,
  client: clientReducer,
  warehouseSetup: warehouseSetupReducer,
  inward: inwardReducer
});

export default reducers;
