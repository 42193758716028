import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authenticationService from './authenticationService';

const initialState = {
  user: null,
  clients: [],
  warehouses: [],
  dashboardInfo: {},
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: ''
};

// Authenticate Registeration
export const register = createAsyncThunk('user/register', async (userData, thunkAPI) => {
  try {
    return await authenticationService.register(userData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Send Otp
export const sendOtp = createAsyncThunk('user/send_otp', async (data, thunkAPI) => {
  try {
    return await authenticationService.sendOtp(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Validate Otp
export const validateOtp = createAsyncThunk('user/validate_otp', async (data, thunkAPI) => {
  try {
    return await authenticationService.validateOtp(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Reset Password
export const resetPassword = createAsyncThunk('user/reset_password', async (data, thunkAPI) => {
  try {
    return await authenticationService.resetPassword(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch OMS Client
export const fetchFilteredClient = createAsyncThunk('organization/filter_client', async (data, thunkAPI) => {
  try {
    return await authenticationService.fetchFilteredClient(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch OMS Warehouse
export const fetchFilteredWarehouse = createAsyncThunk('warehouse/filter_warehouse', async (data, thunkAPI) => {
  try {
    return await authenticationService.fetchFilteredWarehouse(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch WMS Warehouse
export const fetchWmsFilterWarehouse = createAsyncThunk('warehouse/filter_booking_space', async (thunkAPI) => {
  try {
    return await authenticationService.fetchWmsFilterWarehouse();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch WMS Client
export const fetchWmsFilteredClient = createAsyncThunk('warehouse/filter_warehouse_client', async (data, thunkAPI) => {
  try {
    return await authenticationService.fetchWmsFilteredClient(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch WH Partner Warehouse
export const fetchWhPartnerFilteredWarehouse = createAsyncThunk('warehouse/filter_whpartner_warehouse', async (data, thunkAPI) => {
  try {
    return await authenticationService.fetchWhPartnerFilteredWarehouse(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchDashboardDetails = createAsyncThunk('common/dashboard_details', async (data, thunkAPI) => {
  try {
    return await authenticationService.fetchDashboardDetails(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchOrderOverview = createAsyncThunk('common/order_overview', async (data, thunkAPI) => {
  try {
    return await authenticationService.fetchOrderOverview(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(sendOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(validateOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(validateOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(validateOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchFilteredClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFilteredClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clients = action.payload.data;
      })
      .addCase(fetchFilteredClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchFilteredWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFilteredWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouses = action.payload.data;
      })
      .addCase(fetchFilteredWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchWmsFilterWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWmsFilterWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouses = action.payload.data;
      })
      .addCase(fetchWmsFilterWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchWmsFilteredClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWmsFilteredClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clients = action.payload.data;
      })
      .addCase(fetchWmsFilteredClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchWhPartnerFilteredWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWhPartnerFilteredWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouses = action.payload.data;
      })
      .addCase(fetchWhPartnerFilteredWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchDashboardDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDashboardDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.dashboardInfo = action.payload.data;
      })
      .addCase(fetchDashboardDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchOrderOverview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOrderOverview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.dashboardInfo = action.payload.data;
      })
      .addCase(fetchOrderOverview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
