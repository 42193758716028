import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import warehouseService from './warehouseService';

const initialState = {
  warehouses: null,
  warehouse: null,
  inventoriesList: null,
  motherdarkWarehouse: null,
  binLocations: null,
  spaceBooking: null,
  warehousePartners: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: ''
};

export const fetchWarehouse = createAsyncThunk('warehouse/list_warehouse', async (pageNum, thunkAPI) => {
  try {
    return await warehouseService.fetchWarehouse(pageNum);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const warehouseStatus = createAsyncThunk('warehouse/status', async (data, thunkAPI) => {
  try {
    return await warehouseService.warehouseStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchWarehouseDetail = createAsyncThunk('warehouse/getsinglewarehouse', async (_id, thunkAPI) => {
  try {
    return await warehouseService.fetchWarehouseDetail(_id);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addWarehouse = createAsyncThunk('warehouse/add', async (warehouseData, thunkAPI) => {
  try {
    return await warehouseService.addWarehouse(warehouseData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateWarehouse = createAsyncThunk('warehouse/edit', async (newWarehouseData, thunkAPI) => {
  try {
    return await warehouseService.updateWarehouse(newWarehouseData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addSpaceBooking = createAsyncThunk('warehouse/add_booking_space', async (spaceBookingData, thunkAPI) => {
  try {
    return await warehouseService.addSpaceBooking(spaceBookingData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchSpaceBookingList = createAsyncThunk('warehouse/list_booking_space', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await warehouseService.fetchSpaceBookingList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const spaceBookingStatus = createAsyncThunk('warehouse/booking_space_status', async (data, thunkAPI) => {
  try {
    return await warehouseService.spaceBookingStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const singleSpaceBooking = createAsyncThunk('warehouse/booking_space_details', async (data, thunkAPI) => {
  try {
    return await warehouseService.singleSpaceBooking(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateSpaceBooking = createAsyncThunk('warehouse/edit_booking_space', async (newSpaceBookingData, thunkAPI) => {
  try {
    return await warehouseService.updateSpaceBooking(newSpaceBookingData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const listWarehousePartner = createAsyncThunk('warehouse/all_warehouse_partner', async (thunkAPI) => {
  try {
    return await warehouseService.listWarehousePartner();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchExternalWarehouseList = createAsyncThunk('warehouse/external_wh_list', async ({ pageNum, organizationId }, thunkAPI) => {
  try {
    return await warehouseService.fetchExternalWarehouseList(pageNum, organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addExternalWarehouse = createAsyncThunk('warehouse/add_external_wh', async (data, thunkAPI) => {
  try {
    return await warehouseService.addExternalWarehouse(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchExternalWarehouseDetail = createAsyncThunk('warehouse/get_external_wh_details', async (data, thunkAPI) => {
  try {
    return await warehouseService.fetchExternalWarehouseDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const externalWarehouseStatus = createAsyncThunk('warehouse/external_wh_status', async (data, thunkAPI) => {
  try {
    return await warehouseService.externalWarehouseStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateExternalWarehouse = createAsyncThunk('warehouse/update_external_wh', async (data, thunkAPI) => {
  try {
    return await warehouseService.updateExternalWarehouse(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchStnExternalWarehouse = createAsyncThunk('warehouse/stn_external_wh_list', async (data, thunkAPI) => {
  try {
    return await warehouseService.fetchStnExternalWarehouse(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchMotherDarkWarehouseList = createAsyncThunk('warehouse/mother_dark_warehouse_list', async (organizationId, thunkAPI) => {
  try {
    return await warehouseService.fetchMotherDarkWarehouseList(organizationId);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addWarehouseRelation = createAsyncThunk('warehouse/add_warehouse_relation', async (data, thunkAPI) => {
  try {
    return await warehouseService.addWarehouseRelation(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchWarehouseRelationList = createAsyncThunk(
  'warehouse/list_warehouse_relation',
  async ({ pageNum, organizationId }, thunkAPI) => {
    try {
      return await warehouseService.fetchWarehouseRelationList(pageNum, organizationId);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteWarehouseRelation = createAsyncThunk('warehouse/delete_warehouse_relation', async (data, thunkAPI) => {
  try {
    return await warehouseService.deleteWarehouseRelation(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchAllInventories = createAsyncThunk(
  'warehouse/all_inventories',
  async ({ pageNum, spaceBookingId, selectedSKU, selectedBIN }, thunkAPI) => {
    try {
      return await warehouseService.fetchAllInventories(pageNum, spaceBookingId, selectedSKU, selectedBIN);
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addNewBinLocations = createAsyncThunk('warehouse/add_new_bin_locations', async (data, thunkAPI) => {
  try {
    return await warehouseService.addNewBinLocations(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchClientWarehouse = createAsyncThunk('warehouse/client_warehouse_list', async (data, thunkAPI) => {
  try {
    return await warehouseService.fetchClientWarehouse(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouses = action.payload;
      })
      .addCase(fetchWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(warehouseStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(warehouseStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouse = action.payload;
      })
      .addCase(warehouseStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchWarehouseDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWarehouseDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouse = action.payload;
      })
      .addCase(fetchWarehouseDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouse = action.payload;
      })
      .addCase(addWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouse = action.payload;
      })
      .addCase(updateWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addSpaceBooking.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addSpaceBooking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.spaceBooking = action.payload;
      })
      .addCase(addSpaceBooking.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchSpaceBookingList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSpaceBookingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.spaceBooking = action.payload;
      })
      .addCase(fetchSpaceBookingList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(spaceBookingStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(spaceBookingStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.spaceBooking = action.payload;
      })
      .addCase(spaceBookingStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(singleSpaceBooking.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(singleSpaceBooking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.spaceBooking = action.payload;
      })
      .addCase(singleSpaceBooking.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateSpaceBooking.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSpaceBooking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.spaceBooking = action.payload;
      })
      .addCase(updateSpaceBooking.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(listWarehousePartner.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(listWarehousePartner.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehousePartners = action.payload.data;
      })
      .addCase(listWarehousePartner.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchExternalWarehouseList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchExternalWarehouseList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouses = action.payload.data;
      })
      .addCase(fetchExternalWarehouseList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addExternalWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addExternalWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouse = action.payload.data;
      })
      .addCase(addExternalWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchExternalWarehouseDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchExternalWarehouseDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouse = action.payload.data;
      })
      .addCase(fetchExternalWarehouseDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(externalWarehouseStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(externalWarehouseStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouse = action.payload.data;
      })
      .addCase(externalWarehouseStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateExternalWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateExternalWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouse = action.payload.data;
      })
      .addCase(updateExternalWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchStnExternalWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStnExternalWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouse = action.payload.data;
      })
      .addCase(fetchStnExternalWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchMotherDarkWarehouseList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMotherDarkWarehouseList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.motherdarkWarehouse = action.payload.data;
      })
      .addCase(fetchMotherDarkWarehouseList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addWarehouseRelation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addWarehouseRelation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.motherdarkWarehouse = action.payload.data;
      })
      .addCase(addWarehouseRelation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchWarehouseRelationList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWarehouseRelationList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.motherdarkWarehouse = action.payload.data;
      })
      .addCase(fetchWarehouseRelationList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteWarehouseRelation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteWarehouseRelation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.motherdarkWarehouse = action.payload.data;
      })
      .addCase(deleteWarehouseRelation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchAllInventories.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllInventories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.inventoriesList = action.payload;
      })
      .addCase(fetchAllInventories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addNewBinLocations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewBinLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.binLocations = action.payload.data;
      })
      .addCase(addNewBinLocations.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchClientWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClientWarehouse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouses = action.payload;
      })
      .addCase(fetchClientWarehouse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = warehouseSlice.actions;
export default warehouseSlice.reducer;
