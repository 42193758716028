import { useState } from 'react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { FilterContext } from '../../../../../contexts/FilterContext';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Dialog, DialogTitle } from '@mui/material';

// project import
import IconButton from 'components/@extended/IconButton';
import AnimateButton from 'components/@extended/AnimateButton';
import OmsFilter from './OmsFilter';
import WmsFilter from './WmsFilter';
import ClientFilter from './ClientFilter';
import WarehousePartnerFilter from './WarehousePartnerFilter';

// assets
import { ControlOutlined, CloseOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';

// ==============================|| Warehouse Customer Filter ||============================== //

function BootstrapDialogTitle(props) {
  const { onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[600]
          }}
        >
          <CloseOutlined />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

const WarehouseCustomerFilter = (props) => {
  const { setFilterValue } = useContext(FilterContext);
  const { filterValue } = props;
  const theme = useTheme();
  const location = useLocation();
  const [add, setAdd] = useState(false);
  const pathArray = location.pathname.split('/');
  const selectedProduct = pathArray[1];

  const handleAdd = () => {
    setAdd(!add);
  };

  const user = JSON.parse(localStorage.getItem('user'));
  const onFilterChange = () => {
    let data;
    if (selectedProduct === 'oms' && user.organizationType === 2) {
      data = JSON.parse(localStorage.getItem('omsFilteredData'));
    } else if ((selectedProduct === 'wms' || selectedProduct === 'tms') && user.organizationType === 2) {
      data = JSON.parse(localStorage.getItem('wmsFilteredData'));
    } else if (user.organizationType === 3 && user.organizationSubType === 3) {
      data = JSON.parse(localStorage.getItem('clientFilteredData'));
    } else if (user.organizationType === 3 && user.organizationSubType === 4) {
      data = JSON.parse(localStorage.getItem('whPartnerFilteredData'));
    }
    filterValue(data);
    setFilterValue(data);
  };
  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';

  return (
    <>
      <Box sx={{ flexShrink: 0, ml: 0.75 }}>
        <IconButton
          color="secondary"
          variant="light"
          sx={{ color: 'text.primary', bgcolor: add ? iconBackColorOpen : iconBackColor }}
          onClick={(e) => {
            e.stopPropagation();
            handleAdd();
          }}
          aria-label="settings toggler"
        >
          <AnimateButton>
            <ControlOutlined />
          </AnimateButton>
        </IconButton>
      </Box>
      <Dialog maxWidth="md" fullWidth onClose={handleAdd} open={add} sx={{ '& .MuiDialog-paper': { p: 3 } }}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleAdd}></BootstrapDialogTitle>
        {add && selectedProduct === 'oms' && user.organizationType === 2 && (
          <OmsFilter onFilterChange={onFilterChange} onCancel={handleAdd} />
        )}
        {add && (selectedProduct === 'wms' || selectedProduct === 'tms') && user.organizationType === 2 && (
          <WmsFilter onFilterChange={onFilterChange} onCancel={handleAdd} />
        )}
        {add &&
          (selectedProduct === 'oms' || selectedProduct === 'wms' || selectedProduct === 'tms') &&
          user.organizationType === 3 &&
          user.organizationSubType === 3 && <ClientFilter onFilterChange={onFilterChange} onCancel={handleAdd} />}
        {add &&
          (selectedProduct === 'oms' || selectedProduct === 'wms' || selectedProduct === 'tms') &&
          user.organizationType === 3 &&
          user.organizationSubType === 4 && <WarehousePartnerFilter onFilterChange={onFilterChange} onCancel={handleAdd} />}
      </Dialog>
    </>
  );
};

export default WarehouseCustomerFilter;
WarehouseCustomerFilter.propTypes = {
  filterValue: PropTypes.func
};
