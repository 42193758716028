import axios from 'axios';

const API_URL = process.env.REACT_APP_LIVE_API_URL;
const TOKEN = localStorage.getItem('serviceToken');

const addCustomer = async (customerData) => {
  const config = {
    method: 'post',
    url: API_URL + 'customer/add',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: customerData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchCustomerList = async (page, organizationId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `customer/list?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      organizationId: organizationId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const customerStatus = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `customer/status`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchCustomerDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `customer/get_single`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateCustomer = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'customer/edit',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const getAllCustomer = async (organizationId) => {
  const config = {
    method: 'post',
    url: API_URL + `customer/get_all`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: organizationId
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchCustomerAddressList = async (customerId) => {
  const config = {
    method: 'post',
    url: API_URL + `customer/customer_address_list`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: customerId
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchCustomerAddressMasterList = async (page, customerId) => {
  let pageNum = Number(page);
  const config = {
    method: 'post',
    url: API_URL + `customer/list_address?page=${pageNum}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: {
      customerId: customerId
    }
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const fetchCustomerAddressDetail = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + `customer/address_detail`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const addCustomerAddress = async (customerAddressData) => {
  const config = {
    method: 'post',
    url: API_URL + 'customer/add_address',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: customerAddressData
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const updateCustomerAddress = async (data) => {
  const config = {
    method: 'post',
    url: API_URL + 'customer/update_address',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + TOKEN
    },
    data: data
  };
  const response = await axios(config);
  if (response) {
    return response.data;
  }
};

const customerService = {
  addCustomer,
  fetchCustomerList,
  customerStatus,
  fetchCustomerDetail,
  updateCustomer,
  getAllCustomer,
  fetchCustomerAddressList,
  fetchCustomerAddressMasterList,
  fetchCustomerAddressDetail,
  addCustomerAddress,
  updateCustomerAddress
};

export default customerService;
