import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import permissionService from './permissionService';

const initialState = {
  permissions: null,
  products: [],
  employee: null,
  warehouses: [],
  sidemenu: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: ''
};

// Add Permissions
export const addPermissions = createAsyncThunk('common_setup/add_permission', async (data, thunkAPI) => {
  try {
    return await permissionService.addPermissions(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Permissions
export const fetchPermissions = createAsyncThunk('common_setup/fetch_permissions', async (pageNum, thunkAPI) => {
  try {
    return await permissionService.fetchPermissions(pageNum);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Permissions Products
export const fetchPermissionProducts = createAsyncThunk('common_setup/fetch_permission_products', async (thunkAPI) => {
  try {
    return await permissionService.fetchPermissionProducts();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Role Page Permissions
export const fetchRolePermission = createAsyncThunk('common_setup/fetch_organization_permission', async (data, thunkAPI) => {
  try {
    return await permissionService.fetchRolePermission(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add Organization Wise Role Permissions
export const addRolePermission = createAsyncThunk('common_setup/add_role_permissions', async (data, thunkAPI) => {
  try {
    return await permissionService.addRolePermission(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Edit Organization Wise Role Permissions
export const editRolePermission = createAsyncThunk('common_setup/edit_role_permissions', async (data, thunkAPI) => {
  try {
    return await permissionService.editRolePermission(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Roles List
export const rolesList = createAsyncThunk('common_setup/list_roles', async (pageNum, thunkAPI) => {
  try {
    return await permissionService.rolesList(pageNum);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Single Role Permissions
export const singleRolePermissions = createAsyncThunk('common_setup/list_role_permissions', async (data, thunkAPI) => {
  try {
    return await permissionService.singleRolePermissions(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add Employee
export const addEmployee = createAsyncThunk('common_setup/add_employee', async (data, thunkAPI) => {
  try {
    return await permissionService.addEmployee(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Employee
export const listEmployee = createAsyncThunk('common_setup/list_employee', async (pageNum, thunkAPI) => {
  try {
    return await permissionService.listEmployee(pageNum);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Warehouse List
export const listWarehouseAssign = createAsyncThunk('warehouse/list_all', async (thunkAPI) => {
  try {
    return await permissionService.listWarehouseAssign();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Warehouse Customers
export const fetchWarehouseCustomers = createAsyncThunk('warehouse/fetchWarehouseCustomers', async (data, thunkAPI) => {
  try {
    return await permissionService.fetchWarehouseCustomers(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Fetch Employee Detail
export const fetchEmployeeDetail = createAsyncThunk('common_setup/employee_details', async (data, thunkAPI) => {
  try {
    return await permissionService.fetchEmployeeDetail(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Employee Status Toggle
export const employeeStatus = createAsyncThunk('common_setup/update_status', async (data, thunkAPI) => {
  try {
    return await permissionService.employeeStatus(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Employee Updation
export const updateEmployee = createAsyncThunk('common_setup/edit_employee', async (newEmployeeData, thunkAPI) => {
  try {
    return await permissionService.updateEmployee(newEmployeeData);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Fetch Side Menu
export const fetchSideMenu = createAsyncThunk('common_setup/fetch_side_menu', async (data, thunkAPI) => {
  try {
    return await permissionService.fetchSideMenu(data);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const permissionSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(addPermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchPermissionProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPermissionProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.products = action.payload.data;
      })
      .addCase(fetchPermissionProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchRolePermission.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRolePermission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(fetchRolePermission.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addRolePermission.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addRolePermission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(addRolePermission.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editRolePermission.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editRolePermission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(editRolePermission.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(singleRolePermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(singleRolePermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(singleRolePermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(addEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.employee = action.payload;
      })
      .addCase(addEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(listEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(listEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.employee = action.payload;
      })
      .addCase(listEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(listWarehouseAssign.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(listWarehouseAssign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.warehouses = action.payload.data;
      })
      .addCase(listWarehouseAssign.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchWarehouseCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWarehouseCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(fetchWarehouseCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchEmployeeDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEmployeeDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(fetchEmployeeDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(employeeStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(employeeStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(employeeStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(updateEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(fetchSideMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSideMenu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sidemenu = action.payload.data;
      })
      .addCase(fetchSideMenu.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = permissionSlice.actions;
export default permissionSlice.reducer;
