import { lazy } from 'react';

// project import

import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RouteAccessCheck from 'utils/route-guard/RouteAccessCheck';

// render - Setup Routes
const WarehouseRelationList = Loadable(lazy(() => import('pages/client/setups/WarehouseRelationList')));
const WarehouseRelation = Loadable(lazy(() => import('pages/client/setups/warehouserelations')));
const WarehousePincode = Loadable(lazy(() => import('pages/client/setups/warehousepincodes')));

// // render - Warehouse Setup Master
const WarehouseSetup = Loadable(lazy(() => import('pages/warehouse_partner/masters/warehousesetup/warehousesetup')));
const WarehouseLayout = Loadable(lazy(() => import('sections/warehouse_partner/masters/warehousesetup/WarehouseLayout')));
const WarehouseRowList = Loadable(lazy(() => import('sections/warehouse_partner/masters/warehousesetup/row/WarehouseRowList')));
const WarehouseZoneList = Loadable(lazy(() => import('sections/warehouse_partner/masters/warehousesetup/zone/WarehouseZoneList')));
const WarehousePalletRackList = Loadable(
  lazy(() => import('sections/warehouse_partner/masters/warehousesetup/pallet_rack/WarehousePalletRackList'))
);

const WarehouseLocationList = Loadable(
  lazy(() => import('sections/warehouse_partner/masters/warehousesetup/locations/WarehouseLocationList'))
);
const WarehouseLocationSerialization = Loadable(
  lazy(() => import('sections/warehouse_partner/masters/warehousesetup/location-serialization/WarehouseLocationSerialization'))
);
const WarehouseMaster = Loadable(lazy(() => import('pages/warehouse_partner/masters/warehousesetup/WarehouseMaster')));
const WarehouseLocation = Loadable(lazy(() => import('pages/warehouse_partner/masters/warehousesetup/WarehouseLocation')));
const WareHousePickingCriteria = Loadable(lazy(() => import('sections/warehouse_partner/masters/warehousesetup/PickingCriteria')));
const WareHousePickersMapping = Loadable(lazy(() => import('sections/warehouse_partner/masters/warehousesetup/PickersMapping')));

// ==============================|| MAIN ROUTING ||============================== //

const SetupRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'wms',
          children: [
            {
              path: 'setups',
              children: [
                {
                  path: 'warehouse-setup',
                  element: (
                    <RouteAccessCheck permissionName={'Warehouse Setup List'}>
                      <WarehouseSetup />
                    </RouteAccessCheck>
                  ),
                  children: [
                    {
                      path: 'master',
                      element: <WarehouseMaster />,
                      children: [
                        {
                          path: 'pallet-rack-list',
                          element: <WarehousePalletRackList />
                        },
                        {
                          path: 'zone-list',
                          element: <WarehouseZoneList />
                        },
                        {
                          path: 'row-list',
                          element: <WarehouseRowList />
                        }
                      ]
                    },
                    {
                      path: 'location',
                      element: <WarehouseLocation />,
                      children: [
                        {
                          path: 'location-list',
                          element: <WarehouseLocationList />
                        },
                        {
                          path: 'location-serialization',
                          element: <WarehouseLocationSerialization />
                        }
                      ]
                    },
                    {
                      path: 'picking-criteria',
                      element: <WareHousePickingCriteria />
                    },
                    {
                      path: 'pickers-mapping',
                      element: <WareHousePickersMapping />
                    },
                    {
                      path: 'layout',
                      element: <WarehouseLayout />
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'oms',
          children: [
            {
              path: 'setups',
              children: [
                {
                  children: [
                    {
                      path: 'warehouse-relation-list',
                      element: <WarehouseRelationList />
                    },
                    {
                      path: 'warehouse-relation',
                      element: <WarehouseRelation />
                    },
                    {
                      path: 'warehouse-pincode',
                      element: <WarehousePincode />
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default SetupRoutes;
